import React from 'react';
import {Col, Row} from "antd";


import { connect } from "react-redux";
const mapStateToProps = state => {
    return {
        session: state.settings.session,
    }
}
class EmpleadoFichaje extends React.Component{
    constructor(props) {
        super(props)
        this.state={
            //empleado_id: this.props.session.empleado_id ? this.props.session.empleado_id : "",
        }    
     }
 render() {
     return (
        <Row>
            <Col span={24} >
                {/*<FichajeLog empleado_id={this.props.session.empleado_id} contexto="empleado"/>*/}
            </Col>
        </Row>
     )
 }
}
export default connect(mapStateToProps)(EmpleadoFichaje);
