import React from "react";
import {ConnectedRouter} from "react-router-redux";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import "styles/fontawesome_css/css/all.css";
import configureStore, {history} from "./appRedux/store";
import App from "./containers/App/index";

//import store from "./store/index";
import { addArticle } from "./appRedux/actions/index";

export const store = configureStore();
window.store = store;
//window.addArticle = addArticle;
const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
