import {takeEvery, call, put} from "redux-saga/effects";
/*
## Common Redux Saga methods (called Effects):
  fork → Performs a non-blocking operation on the function passed.
  take → Pauses until action received.
  race → Runs effects simultaneously, then cancels them all once one finishes.
  call → Runs function. If it returns a promise, pauses the Saga until resolved.
  put → Dispatches an action.
  select → Runs a selector function to get data from the state.
  takeLatest → Executes the operation, returns only the results of the last call.
  takeEvery → Will return results for all the calls triggered.
 */
import {  selectUsers, selectEmpleado, selectVacaciones, selectBolsaCompensaciones, getDocumentos } from "routes/api/urls"
import { listarDocumentos, listar, get } from "routes/api/Llamadas"

function* userDataRequest() {
  try {
    const payload = yield call(getUserData);
    yield put({ type: "USER_DATA_LOADED", payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function getUserData() {
  return fetch(selectUsers).then(response =>
    response.json()
  );
}
function* empleadosDataRequest(data) {
  try {
    const payload = yield call(getEmpleadosData, data);
    yield put({ type: "EMPLEADOS_DATA_LOADED", payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function getEmpleadosData(data) {
  return get(selectEmpleado, data.data.token)
  /*return fetch(selectEmpleado).then(response =>
    response.json()
  );*/
}

function* vacacionesDataRequest(mensaje) {
  try {
    const payload = yield call(getVacacionesData);
    yield put({ type: "VACACIONES_DATA_LOADED", payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function getVacacionesData() {
  return listar(selectVacaciones)
}

function* bolsaCompensacionesDataRequest() {
  try {
    const payload = yield call(getBolsaCompensacionesData);
    yield put({ type: "BOLSA_COMPENSACIONES_DATA_LOADED", payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function getBolsaCompensacionesData() {
  return listar(selectBolsaCompensaciones)
}



function* DocumentosDataRequest(data) {
  try {
    const payload = yield call(getDocumentosData, data);
    yield put({ type: "DOCUMENTOS_DATA_LOADED", payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function getDocumentosData(data) {
  let bodydata = {
    empleado_id: data.data.empleado_id,
  }
  return listarDocumentos(bodydata, getDocumentos, data.data.token)
}

export default function* rootSaga() {
  yield takeEvery("USER_DATA_REQUESTED", userDataRequest);
  yield takeEvery("EMPLEADOS_DATA_REQUESTED", empleadosDataRequest);
  yield takeEvery("VACACIONES_DATA_REQUESTED", vacacionesDataRequest);
  yield takeEvery("BOLSA_COMPENSACIONES_DATA_REQUESTED", bolsaCompensacionesDataRequest);
  yield takeEvery("DOCUMENTOS_DATA_REQUESTED", DocumentosDataRequest);
  
}
