import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "constants/ActionTypes";
import {LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE} from "../../constants/ThemeSetting";
import { RECUENTO_HORA_DATA_REQUESTED, INFORME_AUSENCIA_DATA_REQUESTED, INFORME_BAJA_DATA_REQUESTED, DOCUMENTOS_DATA_REQUESTED, BOLSA_COMPENSACIONES_DATA_REQUESTED, VACACIONES_DATA_REQUESTED, ADD_FOTO, ADD_ARTICLE, ADD_SESSION, ADD_EMPLEADOS, EMPLEADOS_DATA_REQUESTED, USER_DATA_REQUESTED, LOGIN_REQUESTED } from "../constants/action-types";


export function toggleCollapsedSideNav(navCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function setThemeType(themeType) {
  return {type: THEME_TYPE, themeType};
}

export function setThemeColorSelection(colorSelection) {
  return {type: THEME_COLOR_SELECTION, colorSelection};
}

export function onNavStyleChange(navStyle) {
  return {type: NAV_STYLE, navStyle};
}

export function onLayoutTypeChange(layoutType) {
  return {type: LAYOUT_TYPE, layoutType};
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}


// MY_ACTIONS

/*
export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload }
};

export function addEmpleados(payload) {
  return { type: ADD_EMPLEADOS, payload }
};



//TRAE TODOS LOS USUARIOS DESDE API
export function getUserData() {
  return { type: USER_DATA_REQUESTED };
}
*/
//GUARDA INFO DE SESSION EN STORE
export function addSession(payload) {
  return { type: ADD_SESSION, payload };
}
//TRAE TODOS LOS EMPLEADOS DESDE API
export function getEmpleadosData(data) {
  return { 
    type: EMPLEADOS_DATA_REQUESTED, 
    data, 
  };
}
//GUARDA FOTO EN STORE
export function addFoto(payload) {
  return { type: ADD_FOTO, payload };
}
/*
//TRAE TODOS LAS VACACIONES DESDE API
export function getVacacionesData() {
  return { type: VACACIONES_DATA_REQUESTED, };
}

//TRAE TODOS LA BOLSA DE COMPENSACIONES DESDE API
export function getBolsaCompensacionesData() {
  return { type: BOLSA_COMPENSACIONES_DATA_REQUESTED };
}

//TRAE TODOS LOS INFORMES DE BAJAS DESDE API
export function getInformeBajaData() {
  return { type: INFORME_BAJA_DATA_REQUESTED };
}

//TRAE TODOS LOS INFORMES DE AUSENCIAS DESDE API
export function getInformeAusenciaData() {
  return { type: INFORME_AUSENCIA_DATA_REQUESTED };
}

//TRAE TODOS LOS RECUENTOS DE HORAS (DE FICHAJES) DESDE API
export function getRecuentoHoraData() {
  return { type: RECUENTO_HORA_DATA_REQUESTED };
}

//TRAE TODOS LOS DOCUMENTOS DESDE API
export function getDocumentosData(data) {
  return { 
    type: DOCUMENTOS_DATA_REQUESTED, 
    data, 
  };
}

*/