import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import DayPicker from 'react-day-picker';

import {
  Col, Row, Alert,
  Button, Spin,
  Select, Input, Form,
  Card, Modal, Table, Icon, Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  employees,
  employeeUpdate,
  getRoles,
  provincias,
  projectByClient,
  client,
  tipoHora,
  tipoTarea,
  getEmpleadoById, bajaEmpleadoUrl
} from "routes/api/urls";
import { post, get, put } from "routes/api/Llamadas";
import moment from "moment";
import Search from "antd/lib/input/Search";

const { Option } = Select;

const mapStateToProps = state => ({
  session: state.settings.session
});

const WEEKDAYS_SHORT = {
  es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
};
const MONTHS = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
};

class ListEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      dataSourceRole: [],
      dataSourceCalendar: [],
      dataSourceProject: [],
      dataSourceClient: [],
      dataSourceTipoTarea: [],
      dataSourceTipoHora: [],
      dataEmpleado: [],
      flagEdit: false,
      loading: true,
      nombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipoIdentificacion: null,
      Identificacion: '',
      seguridadSocial: '',
      sexo: null,
      telefono: '',
      emailCorp: '',
      emailPers: '',
      proyectoDefecto: '',
      calendario_id: '',
      fechaAlta: new Date(moment()),
      fechaAltaShow: moment().format("YYYY-MM-DD"),
      isStaff: false,
      role: null,
      cliente_id: null,
      proyecto_id: null,
      tipo_horas: [],
      tipo_tareas: [],
      duracionJornada: '8.5',
      inicioJornada: '07:00',
      idEmpleadoUpdate: null,
      bajaEmpleado: false,
      nombreEmpleado: null,
      visible: false,
      visibleDatos: true,
      visibleProyecto: false,
      visibleContacto: false,
      visibleHorario: false,
      visibleBaja: false,
      visibleEdit: true,
      disabledProject: true,
      messageProyectiListado: 'Seleccione cliente para cargar Listado',
      errorDuracionHora: false,
      errorProvincia: false,
      errorRol: false,
      errorTipoTarea: false,
      errorTipoHora: false,
      errorInicioJornada: false,
      titlemodal: "Agregar Empleado",
      fields: { nombre: '', primerApellido: '', segundoApellido: '', tipoIdentificacion: null, Identificacion: '', seguridadSocial: '', sexo: null, telefono: '', emailCorp: '', emailPers: '', calendario_id: '', role: null, cliente_id: null, proyecto_id: null, tipo_horas: [], tipo_tareas: [], duracionJornada: '8.5', inicioJornada: '07:00' },
      errors: {},
      disabledSave: false,
      pickDate: false,

    };

  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Nombre
    if (!fields["nombre"]) {
      formIsValid = false;
      errors["nombre"] = "No puede ser vacío";
    }

    if (typeof fields["nombre"] !== "undefined") {
      // if (!fields["nombre"].match(/^[a-zA-Z]+$/)) {
      if (!fields["nombre"].match(/^[a-zA-Z\sñáéíóúÁÉÍÓÚ]+$/)) {
        console.log('TEST PRUEBAS ---> ', fields.nombre)
        formIsValid = false;
        errors["nombre"] = "Solo se permiten letras";
      }
    }
    //primerApellido
    if (!fields["primerApellido"]) {
      formIsValid = false;
      errors["primerApellido"] = "No puede ser vacío";
    }

    if (typeof fields["primerApellido"] !== "undefined") {
      if (!fields["primerApellido"].match(/^[a-zA-Z\sñáéíóúÁÉÍÓÚ]+$/)) {
        formIsValid = false;
        errors["primerApellido"] = "Solo se permiten letras";
      }
    }
    //segundoApellido
    if (!fields["segundoApellido"]) {
      formIsValid = false;
      errors["segundoApellido"] = "No puede ser vacío";
    }

    if (typeof fields["segundoApellido"] !== "undefined") {
      if (!fields["segundoApellido"].match(/^[a-zA-Z\sñáéíóúÁÉÍÓÚ]+$/)) {
        formIsValid = false;
        errors["segundoApellido"] = "Solo se permiten letras";
      }
    }
    //tipoIdentificacion
    if (!fields["tipoIdentificacion"]) {
      formIsValid = false;
      errors["tipoIdentificacion"] = "Debe seleccionar uno";
    }

    //Identificacion
    if (!fields["Identificacion"]) {
      formIsValid = false;
      errors["Identificacion"] = "No puede ser vacío";
    }

    //sexo
    if (!fields["sexo"]) {
      formIsValid = false;
      errors["sexo"] = "Debe seleccionar uno";
    }

    //telefono
    if (!fields["telefono"]) {
      formIsValid = false;
      errors["telefono"] = "No puede ser vacío";
    }

    let reNumeros = /^([0-9])*$/
    let letOkNumero = reNumeros.exec(fields["telefono"]);
    if (!letOkNumero) {
      formIsValid = false;
      errors["telefono"] = "Solo se permiten números";
    }

    //emailCorp
    if (!fields["emailCorp"]) {
      formIsValid = false;
      errors["emailCorp"] = "No puede ser vacío";
    }

    let reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let letOkEmailCorp = reEmail.exec(fields["emailCorp"]);
    if (!letOkEmailCorp) {
      formIsValid = false;
      errors["emailCorp"] = "No es un email válido";
    }

    //emailPers
    if (!fields["emailPers"]) {
      formIsValid = false;
      errors["emailPers"] = "No puede ser vacío";
    }

    let letOkEmailPers = reEmail.exec(fields["emailPers"]);
    if (!letOkEmailPers) {
      formIsValid = false;
      errors["emailPers"] = "No es un email válido";
    }

    //proyectoDefecto
    /*
    if (!fields["proyectoDefecto"]) {
      formIsValid = false;
      errors["proyectoDefecto"] = "Debe seleccionar uno";
    }*/

    //calendario_id
    if (!fields["calendario_id"]) {
      formIsValid = false;
      errors["calendario_id"] = "Debe seleccionar uno";
    }

    //role
    if (!fields["role"]) {
      formIsValid = false;
      errors["role"] = "Debe seleccionar uno";
    }

    //cliente_id
    if (!fields["cliente_id"]) {
      formIsValid = false;
      errors["cliente_id"] = "Debe seleccionar uno";
    }

    //proyecto_id
    if (!fields["proyecto_id"]) {
      formIsValid = false;
      errors["proyecto_id"] = "Debe seleccionar uno";
    }

    //tipo_horas
    if (fields["tipo_horas"].length == 0) {
      formIsValid = false;
      errors["tipo_horas"] = "Debe seleccionar al menos uno";
    }

    //tipo_tareas
    if (fields["tipo_tareas"].length == 0) {
      formIsValid = false;
      errors["tipo_tareas"] = "Debe seleccionar al menos uno";
    }

    //inicioJornada
    if (!fields["inicioJornada"]) {
      formIsValid = false;
      errors["inicioJornada"] = "No puede ser vacío";
    }

    //duracionJornada
    if (!fields["duracionJornada"]) {
      formIsValid = false;
      errors["duracionJornada"] = "No puede ser vacío";
    }

    this.setState({ errors: errors });

    console.log(this.state.errors);

    return formIsValid;
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      loading: false
    });
    this.handleResetForm();
  };

  handleOk = async () => {

    this.setState({ loading: true });
    if (this.state.bajaEmpleado) {
      this.handleBajaEmpleado(this.state.idEmpleadoUpdate);
      this.handleResetForm();
      this.setState({ loading: false })
      return
    }
    let tipoHoras = '{' + (this.state.tipo_horas).join() + '}';
    let tipoTareas = '{' + (this.state.tipo_tareas).join() + '}';

    let data = {
      nombre: this.state.nombre,
      primerApellido: this.state.primerApellido,
      segundoApellido: this.state.segundoApellido,
      tipoIdentificacion: this.state.tipoIdentificacion,
      Identificacion: this.state.Identificacion,
      seguridadSocial: this.state.seguridadSocial,
      sexo: this.state.sexo,
      telefono: this.state.telefono,
      emailCorp: this.state.emailCorp,
      emailPers: this.state.emailPers,
      proyectoDefecto: this.state.proyecto_id,
      calendario_id: this.state.calendario_id,
      fechaAlta: this.state.fechaAltaShow,
      isStaff: this.state.isStaff,
      role: this.state.role,
      cliente_id: this.state.cliente_id,
      proyecto_id: this.state.proyecto_id,
      tipo_horas: tipoHoras,
      tipo_tareas: tipoTareas,
      duracionJornada: this.state.duracionJornada,
      inicioJornada: this.state.inicioJornada,
      id: this.state.idEmpleadoUpdate,
      baja_empleado: this.state.bajaEmpleado,
      id_user: this.props.session.user_id
    }
    console.log('data a enviar', data)
    if (this.handleValidation()) {
      this.setState({
        disabledSave: true
      });
      this.save(data, employees);

    } else {
      console.log(data);
    }

  };

  handleResetForm = () => {
    //cambio de tipos de hora dentro de la modal detalle de tarea
    this.setState({
      nombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipoIdentificacion: null,
      Identificacion: '',
      seguridadSocial: '',
      sexo: null,
      telefono: '',
      emailCorp: '',
      emailPers: '',
      proyectoDefecto: '',
      calendario_id: '',
      fechaAlta: '',
      isStaff: false,
      role: null,
      cliente_id: null,
      proyecto_id: null,
      tipo_horas: [],
      tipo_tareas: [],
      duracionJornada: '8.5',
      inicioJornada: '07:00',
      visible: false,
      visibleDatos: true,
      visibleProyecto: false,
      visibleContacto: false,
      visibleHorario: false,
      disabledProject: true,
      disabledSave: false,
      messageProyectiListado: 'Seleccione cliente para cargar Listado',
      flagEdit: false,
      bajaEmpleado: false,
      nombreEmpleado: null
    });
  }

  handleChangeInput = (event) => {
    console.log('datos ------->', event.target.value)
    let fields_ = this.state.fields;

    let name = event.target.name;
    fields_[name] = event.target.value;
    this.setState({ [name]: event.target.value, fields: fields_ })
    console.log(fields_);
  }

  handleSelectChangeRole = (value) => {
    let fields_ = this.state.fields;
    fields_['role'] = value;

    this.setState({
      role: value,
      fields: fields_
    });
  }

  handleSelectChangeIdent = (value) => {
    let fields_ = this.state.fields;
    fields_['tipoIdentificacion'] = value;

    this.setState({
      tipoIdentificacion: value,
      fields: fields_
    });
  }

  handleSelectChangeSex = (value) => {
    let fields_ = this.state.fields;
    fields_['sexo'] = value;

    this.setState({
      sexo: value,
      fields: fields_
    });
  }

  handleSelectChangeCalendario = (value) => {
    let fields_ = this.state.fields;
    fields_['calendario_id'] = value;
    console.log('calendario poniendo--->', value)
    this.setState({
      calendario_id: value,
      fields: fields_
    });
  }

  handleSelectChangeClient = (value) => {
    //console.log("cliente", value);
    let fields_ = this.state.fields;
    fields_['cliente_id'] = value;

    this.setState({
      cliente_id: value,
      messageProyectiListado: 'Cargando Listado',
      fields: fields_
    });
    this.getProjects(value);
  }

  handleSelectChangeProject = (value) => {
    let fields_ = this.state.fields;
    fields_['proyecto_id'] = value;

    this.setState({
      proyecto_id: value,
      fields: fields_
    });
  }

  handleVisibleInfo = (nameTab) => {

    this.setState({
      visibleEdit: true,
      visibleDatos: false,
      visibleProyecto: false,
      visibleContacto: false,
      visibleHorario: false,
      visibleBaja: false
    });

    this.setState({ [nameTab]: true })
  }

  handleSelectAllTarea = (value) => {
    let all = this.state.tipo_tareas;
    let fields_ = this.state.fields;

    if (value && value.length) {
      if (value.length === all.length) {
        return [];
      }
      fields_['tipo_tareas'] = value;
      this.setState({ tipo_tareas: value, fields: fields_ })
      return [...all];
    }

    fields_['tipo_tareas'] = value;
    this.setState({ tipo_tareas: value, fields: fields_ })
    return value;
  }

  handleSelectAllHora = (value) => {
    let all = this.state.tipo_horas;
    let fields_ = this.state.fields;

    if (value && value.length) {
      if (value.length === all.length) {
        return [];
      }
      fields_['tipo_horas'] = value;
      this.setState({ tipo_horas: value, fields: fields_ })
      console.log(value);
      return [...all];
    }

    fields_['tipo_horas'] = value;
    this.setState({ tipo_horas: value, fields: fields_ })
    return value;
  }

  handleChangeHour = (event) => {
    //manejo de cambio de inputs de hora, usamos una expresion regular 
    // con un entero de 0 a 9 seguido de un Punto (.) y el unico decimal admitido es el 5 
    var re = /^([0-8]{1}(\.[5]{1})?)$/;
    let inputHour = event.target.value;
    let comprobacion = inputHour.split(".");

    if (comprobacion.length > 1 && comprobacion[1] === "") {
      this.setState({
        duracionJornada: event.target.value,
        errorDuracionHora: false
      })
    }
    if (inputHour === "") {
      this.setState({
        duracionJornada: event.target.value,
        errorDuracionHora: false
      })
    }
    var OK = re.exec(inputHour);

    if (OK) {
      this.setState({
        duracionJornada: event.target.value,
        errorDuracionHora: false
      })
    } else {

      this.setState({ errorDuracionHora: true })
    }
  }

  handleChangeCheckbox = (event) => {
    let check = event.target.checked;
    console.log(check)
    this.setState({ isStaff: check })
  }

  handleDayClick = (day, { selected, disabled }) => { //, { selected }
    debugger;
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    console.log('dia seleccionado--->', day, selected, disabled)
    this.setState({
      fechaAlta: day, //selected ? undefined :
    });
    console.log('campos fields: ', this.state.fields)

  }

  pickADate = async () => {
    //Levanta modal seleccion de fecha
    this.setState({ pickDate: true });
  }
  closeDatePicker = async () => {
    //Cierra modal seleccion de fecha
    this.setState({ pickDate: false });
  }
  manipulateDates = async () => {
    //Aquí haremos toda la lógica para consultar la fecha  que se seleccione

    this.setState({
      fechaAltaShow: moment(this.state.fechaAlta).format("Y-MM-DD"),
      pickDate: false,
    });

  }

  async componentDidMount() {
    //debugger;
    this.setState({ loading: true });//mascara para mostrar cargando

    await this.getEmployee();
    await this.getRole();
    await this.getClient();
    await this.getCalendar();
    await this.getTipoHora();
    await this.getTipoTarea();
    console.log('ESTADO------>', this.state)
    console.log('props------>', this.props.session)

  }

  async save(data, url) {
    debugger;
    console.log('id empleado-->', this.state.idEmpleadoUpdate)
    try {
      let token = this.props.session.auth_token;
      this.setState({ loading: true });
      let resp = {};

      if (this.state.flagEdit === true) {
        resp = await put(data, employeeUpdate, token);
      } else {
        resp = await post(data, url, token);
      }

      this.setState({ loading: false, visible: false, visibleBaja: false });
      await this.getEmployee();
      this.handleResetForm();
    } catch (Error) {
      console.error(Error);
    }
  }

  agregarEmpleado = async () => {
    this.setState({
      visible: true,
      visibleEdit: true,
      visibleDatos: true,
      visibleProyecto: false,
      visibleContacto: false,
      visibleHorario: false,
      visibleBaja: false
    });
  }

  editarEmpleado = async (idEmpleado) => {
    this.setState({
      flagEdit: true,
      loading: true,
      idEmpleadoUpdate: idEmpleado,
      titlemodal: "Editar Empleado"
    });
    this.getEmpleadoById(idEmpleado);
  }

  darBajaEmpleado = async (idEmpleado, nombre) => {
    this.setState({
      flagEdit: true,
      visibleBaja: true,
      visibleEdit: false,
      visible: true,
      loading: true,
      idEmpleadoUpdate: idEmpleado,
      titlemodal: "Dar de Baja a Empleado",
      bajaEmpleado: true,
      nombreEmpleado: nombre
    });
  }
  handleBajaEmpleado = async (idEmpleado) => {
    let token = this.props.session.auth_token;
    let resp = await get(bajaEmpleadoUrl + idEmpleado, token);
    console.log('llamando getclient desde handlebajaempleado')
    await this.getEmployee();
    console.log('datos baja--->', resp);
  }
  getEmployee = async () => {
    const { empleado_id, auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(employees, auth_token);
    console.log(resp.data);
    debugger;

    if (resp.data.length > 0) {
      this.setState({
        dataSource: resp.data
      });
      this.setState({ loading: false });
    }

  }

  getEmpleadoById = async (id_empleado) => {
    const { auth_token } = this.props.session;


    let resp = await post({ empleado_id: id_empleado }, getEmpleadoById, auth_token);
    let dataResponse = JSON.parse(JSON.stringify(resp.data));
    let dataEmpleado = dataResponse.data.empleado;
    let dataProyectoEmpleado = dataResponse.data.proyecto_empleado
    let dataUser = dataResponse.data.user

    let tipo_horas_array = []
    let tipo_tareas_array = []

    let arrayHoras = (((dataProyectoEmpleado.tipo_horas).replace('{', '')).replace('}', '')).split(',');
    let arrayTareas = (((dataProyectoEmpleado.tipo_tareas).replace('{', '')).replace('}', '')).split(',');
    //console.log(arrayTareas.length, arrayHoras.length);

    for (var i = 0; i < arrayHoras.length; i++) {
      //console.log(arrayHoras[i]);
      tipo_horas_array.push(parseInt(arrayHoras[i]))
    };


    for (var k = 0; k < arrayTareas.length; k++) {
      //console.log(arrayTareas[k]);
      tipo_tareas_array.push(parseInt(arrayTareas[k]))
    };

    this.getProjects(dataProyectoEmpleado.cliente_id);

    //if(dataResponse.length > 0){
    this.setState({
      nombre: dataEmpleado.nombre,
      primerApellido: dataEmpleado.primerApellido,
      segundoApellido: dataEmpleado.segundoApellido,
      tipoIdentificacion: dataEmpleado.tipoIdentificacion,
      Identificacion: dataEmpleado.identificacion,
      seguridadSocial: dataEmpleado.seguridadSocial,
      sexo: dataEmpleado.sexo,
      telefono: dataEmpleado.telefono,
      emailCorp: dataEmpleado.emailCorp,
      emailPers: dataEmpleado.emailPers,
      proyectoDefecto: parseInt(dataEmpleado.proyectoDefecto),
      calendario_id: parseInt(dataEmpleado.calendario_id),
      isStaff: dataEmpleado.isStaff,
      role: dataUser.role,
      fechaAlta: new Date(dataEmpleado.fechaAlta),
      fechaAltaShow: moment(dataEmpleado.fechaAlta).format("YYYY-MM-DD"),
      cliente_id: dataProyectoEmpleado.cliente_id,
      proyecto_id: parseInt(dataProyectoEmpleado.proyecto_id),
      tipo_horas: tipo_horas_array,
      tipo_tareas: tipo_tareas_array,
      duracionJornada: dataProyectoEmpleado.duracionJornada,
      inicioJornada: dataProyectoEmpleado.inicioJornada,
      fields: {
        nombre: dataEmpleado.nombre,
        primerApellido: dataEmpleado.primerApellido,
        segundoApellido: dataEmpleado.segundoApellido,
        tipoIdentificacion: dataEmpleado.tipoIdentificacion,
        Identificacion: dataEmpleado.identificacion,
        seguridadSocial: dataEmpleado.seguridadSocial,
        sexo: dataEmpleado.sexo,
        telefono: dataEmpleado.telefono,
        emailCorp: dataEmpleado.emailCorp,
        emailPers: dataEmpleado.emailPers,
        proyectoDefecto: parseInt(dataEmpleado.proyectoDefecto),
        calendario_id: parseInt(dataEmpleado.calendario_id),
        isStaff: dataEmpleado.isStaff,
        role: dataUser.role,
        fechaAlta: new Date(dataEmpleado.fechaAlta),
        fechaAltaShow: moment(dataEmpleado.fechaAlta).format("YYYY-MM-DD"),
        cliente_id: dataProyectoEmpleado.cliente_id,
        proyecto_id: parseInt(dataProyectoEmpleado.proyecto_id),
        tipo_horas: tipo_horas_array,
        tipo_tareas: tipo_tareas_array,
        duracionJornada: dataProyectoEmpleado.duracionJornada,
        inicioJornada: dataProyectoEmpleado.inicioJornada,
      }
    });
    this.agregarEmpleado();
    this.setState({ loading: false });
    //}
    debugger;

  }

  getRole = async () => {
    const { auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(getRoles, auth_token);

    if (resp.data.length > 0) {
      this.setState({
        dataSourceRole: resp.data
      });
      this.setState({ loading: false });
    }

  }

  getClient = async () => {
    const { auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(client, auth_token);

    if (resp.data.length > 0) {
      this.setState({
        dataSourceClient: resp.data
      });
      this.setState({ loading: false });
    }

    console.log(this.state.dataSourceClient)

  }

  getCalendar = async () => {
    const { auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(provincias, auth_token);

    if (resp.data.length > 0) {
      this.setState({
        dataSourceCalendar: resp.data
      });
      this.setState({ loading: false });
    }

  }

  getProjects = async (idClient) => {
    this.setState({ loading: false });
    const { auth_token } = this.props.session;
    this.setState({ disabledProject: true });

    let url = projectByClient + idClient;

    let resp = await get(url, auth_token);

    if (resp.length > 0) {
      this.setState({
        dataSourceProject: resp
      });
      this.setState({
        disabledProject: false,
        messageProyectiListado: 'Seleccione Proyecto'
      });

    }

  }

  getTipoTarea = async () => {
    const { auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(tipoTarea, auth_token);

    if (resp.data.length > 0) {
      this.setState({
        dataSourceTipoTarea: resp.data
      });
      this.setState({ loading: false });
    }

  }

  getTipoHora = async () => {
    const { auth_token } = this.props.session;
    this.setState({ loading: true });
    let resp = await get(tipoHora, auth_token);

    if (resp.data.length > 0) {
      this.setState({
        dataSourceTipoHora: resp.data
      });
      this.setState({ loading: false });
    }

  }

  render() {

    let now = new Date();
    let year = now.getFullYear();
    let b_day = moment(now).format("DD")
    let b_month = moment(now).format("MM") - 1
    if (this.state.dataSource.length > 0) {
      let nombresFilters = this.state.dataSource.map(x => {
        return {
          text: x['nombre'],
          value: x['nombre']
        }
      })
      console.log('nombres filters', nombresFilters)
    }

    const dashColumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "Id",
      },
      {
        title: "Tipo Identificador",
        dataIndex: "tipoIdentificacion",
        key: "tipoIdentificacion"
      },
      {
        title: "Nº Identificador",
        dataIndex: "Identificacion",
        key: "Identificacion"
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <>
              <Input autoFocus={true} placeholder="Escribe el nombre"
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
              
                }}
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <div>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  confirm()
                }}
                  type="primary">Buscar</Button>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  clearFilters();
                }}
                  type="danger">Resetear</Button>
              </div>
            </>
          )
        },
        filterIcon: () => {
          return <SearchOutlined />
        },
        onFilter: (value, record) => {
          if(!record.nombre){
            return false;
          }
          if(value.trim()=='') return true;
          return record.nombre.toLowerCase().trim().includes(value.toLowerCase().trim())
        }
      },
      {
        title: "Primer Apellido",
        dataIndex: "primerApellido",
        key: "primerApellido",
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <>
              <Input autoFocus placeholder="Escribe el primer apellido"
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }}
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <div>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  confirm()
                }}
                  type="primary">Buscar</Button>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  clearFilters();
                }}
                  type="danger">Resetear</Button>
              </div>
            </>
          )
        },
        filterIcon: () => {
          return <SearchOutlined />
        },
        onFilter: (value, record) => {
          if(!record.primerApellido){
            return false;
          }
          if(value.trim()=='') return true;
          return record.primerApellido.toLowerCase().trim().includes(value.toLowerCase().trim())
        }
      },
      {
        title: "Segundo Apellido",
        dataIndex: "segundoApellido",
        key: "segundoApellido",
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <>
              <Input autoFocus placeholder="Escribe el segundo apellido"
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }}
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
              <div>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  confirm()
                }}
                  type="primary">Buscar</Button>
                <Button style={{ marginBottom: '0px' }} onClick={() => {
                  clearFilters();
                }}
                  type="danger">Resetear</Button>
              </div>
            </>
          )
        },
        filterIcon: () => {
          return <SearchOutlined />
        },
        onFilter: (value, record) => {
          if(!record.segundoApellido){
            return false;
          }
          if(value.trim()=='') return true;
          return record.segundoApellido.toLowerCase().trim().includes(value.toLowerCase().trim())
        }
      },
      {
        title: "Email",
        dataIndex: "emailCorp",
        key: "emailCorp"
      },
      {
        title: "Acciones",
        dataIndex: "id",
        key: "id",
        fixed: "right",
        render: (value, dataSource) => (
          <span>
            <Button onClick={() => this.darBajaEmpleado(dataSource.id, dataSource.nombre + ' ' + dataSource.primerApellido + ' ' + dataSource.segundoApellido)} type="danger" icon="delete" size="small" style={{ marginBottom: "0px" }} />
            <Button onClick={() => this.editarEmpleado(dataSource.id)} type="primary" icon="edit" size="small" style={{ marginBottom: "0px" }} />
          </span>
        )
      }
    ];

    const { loading } = this.state;
    const ListTipoTarea = this.state.dataSourceTipoTarea ? this.state.dataSourceTipoTarea.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.tipo}</Option>) : null
    const ListTipoHora = this.state.dataSourceTipoHora ? this.state.dataSourceTipoHora.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.tipo}</Option>) : null
    const ListRoles = this.state.dataSourceRole ? this.state.dataSourceRole.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.nombre}</Option>) : null
    const ListProvincia = this.state.dataSourceCalendar ? this.state.dataSourceCalendar.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.nombre}</Option>) : null
    const ListClient = this.state.dataSourceClient ? this.state.dataSourceClient.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.nombre}</Option>) : null
    const ListProject = this.state.dataSourceProject ? this.state.dataSourceProject.map(x => <Option key={x.id} id={x.id} value={x.id} >{x.nombre}</Option>) : null

    const disabledDays = [
      { daysOfWeek: [0, 6]},
      {before: new Date()}
      // {
      //  before: new Date(year, 10, 24),
      //  after: new Date(year, b_month-1, 31),
      // }
    ]

    return (
      <div>
        <Spin spinning={loading}>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
              <Card title="Empleados" className="gx-card" extra={
                <span>
                  <Button block type="primary" onClick={this.agregarEmpleado} style={{ marginBottom: "0px", marginRight: "0px" }}>
                    <Icon type="plus" />
                    Agregar Empleado
                  </Button>
                </span>}> {/**/}
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={this.state.dataSource}
                  columns={dashColumns}
                  pagination={true}
                  filterMultiple={true}
                />
              </Card>
            </Col>
          </Row>
        </Spin>
        <Modal
          title={this.state.titlemodal}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Confirmar" type="primary" disabled={this.state.disabledSave} onClick={this.handleOk}>
              Guardar
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>
          ]}
        >
          {this.state.visibleBaja === true ?
            <div>
              ¿Esta seguro que desea dar de baja al empleado {this.state.nombreEmpleado}?
            </div>
            : null}
          {this.state.visibleEdit === true ?
            <Form layout="vertical">
              <div>{[
                <Button key="btn_data_personal" type="primary" onClick={() => this.handleVisibleInfo('visibleDatos')}>
                  Datos Personales
                </Button>,
                <Button key="btn_contacto" type="primary" onClick={() => this.handleVisibleInfo('visibleContacto')}>
                  Contacto
                </Button>,
                <Button key="btn_proyectos" type="primary" onClick={() => this.handleVisibleInfo('visibleProyecto')}>
                  Proyectos
                </Button>,
                <Button key="btn_horario" type="primary" onClick={() => this.handleVisibleInfo('visibleHorario')}>
                  Horario
                </Button>
              ]}
              </div>
              {this.state.visibleDatos === true ?
                <div>
                  <Form.Item label="Nombre">
                    <Input
                      name="nombre"
                      type="text"
                      className="center-block"
                      value={this.state.nombre}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["nombre"]}</span>
                  </Form.Item>
                  <Form.Item label="Primer Apellido">
                    <Input
                      name="primerApellido"
                      type="text"
                      className="center-block"
                      value={this.state.primerApellido}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["primerApellido"]}</span>
                  </Form.Item>
                  <Form.Item label="Segundo Apellido">
                    <Input
                      name="segundoApellido"
                      type="text"
                      className="center-block"
                      value={this.state.segundoApellido}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["segundoApellido"]}</span>
                  </Form.Item>
                  <Form.Item label="Tipo Identificación">
                    <Select
                      value={this.state.tipoIdentificacion}
                      placeholder={"Seleccione Tipo"}
                      required={true}
                      onChange={this.handleSelectChangeIdent}>
                      <Option value="DNI">DNI</Option>
                      <Option value="NIE">NIE</Option>
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["tipoIdentificacion"]}</span>
                  </Form.Item>
                  <Form.Item label="Nº de Identificación">
                    <Input
                      name="Identificacion"
                      type="text"
                      className="center-block"
                      value={this.state.Identificacion}
                      maxLength={10}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["Identificacion"]}</span>
                  </Form.Item>
                  <Form.Item label="Sexo">
                    <Select
                      value={this.state.sexo}
                      placeholder={"Seleccione Sexo"}
                      required={true}
                      defaultValue="" onChange={this.handleSelectChangeSex}>
                      <Option value="Masculino">Masculino</Option>
                      <Option value="Femenino">Femenino</Option>
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["sexo"]}</span>
                  </Form.Item>
                </div>
                : null}
              {this.state.visibleContacto === true ?
                <div>
                  <Form.Item label="Email Coorporativo">
                    <Input
                      name="emailCorp"
                      type="email"
                      className="center-block"
                      value={this.state.emailCorp}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["emailCorp"]}</span>
                  </Form.Item>
                  <Form.Item label="Email Personal">
                    <Input
                      name="emailPers"
                      type="email"
                      className="center-block"
                      value={this.state.emailPers}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["emailPers"]}</span>
                  </Form.Item>
                  {/* <Form.Item label="Seguridad Social">
                    <Input
                      name="seguridadSocial"
                      type="text"
                      className="center-block"
                      value={this.state.seguridadSocial}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["seguridadSocial"]}</span>
                  </Form.Item> */}
                  <Form.Item label="Telefono">
                    <Input
                      name="telefono"
                      type="text"
                      className="center-block"
                      value={this.state.telefono}
                      maxLength={9}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["telefono"]}</span>
                  </Form.Item>
                </div>
                : null}
              {this.state.visibleProyecto === true ?
                <div>
                  <Form.Item label="Cliente">
                    <Select
                      placeholder={"Seleccione Cliente"}
                      value={this.state.cliente_id}
                      required={true}
                      style={{ color: "black" }} onChange={this.handleSelectChangeClient}>
                      {ListClient}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["cliente_id"]}</span>
                  </Form.Item>
                  <Form.Item label="Proyecto">
                    <Select
                      placeholder={this.state.messageProyectiListado}
                      value={this.state.proyecto_id}
                      style={{ color: "black" }}
                      required={true}
                      disabled={(this.state.disabledProject) ? true : false}
                      onChange={this.handleSelectChangeProject}>
                      {ListProject}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["proyecto_id"]}</span>
                  </Form.Item>
                </div>
                : null}
              {this.state.visibleHorario === true ?
                <div>
                  <Form.Item label="Fecha de Ingreso">
                    <Input
                      name="fechaAlta"
                      type="text"
                      className="center-block"
                      value={this.state.fechaAltaShow}
                      maxLength={100}
                      required={true}
                      onChange={this.handleChangeInput}
                      onClick={this.pickADate}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["fechaAlta"]}</span>
                  </Form.Item>
                  <Form.Item label="Provincia">
                    <Select
                      value={this.state.calendario_id}
                      placeholder={"Seleccione Provincia"}
                      required={true}
                      style={{ color: "black" }} onChange={this.handleSelectChangeCalendario}>
                      {ListProvincia}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["calendario_id"]}</span>
                  </Form.Item>
                  <Form.Item label="Rol">
                    <Select
                      value={this.state.role}
                      placeholder={"Seleccione Rol"}
                      required={true}
                      style={{ color: "black" }} onChange={this.handleSelectChangeRole}>
                      {ListRoles}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["role"]}</span>
                  </Form.Item>
                  <Form.Item name="isStaff" valuepropname="checked">
                    <Checkbox
                      checked={this.state.isStaff}
                      onChange={(event) => {
                        this.handleChangeCheckbox(event);
                      }}>
                      Es Staff?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="Tipo de Horas">
                    <Select
                      mode="multiple"
                      placeholder="Selecciona Tipo Horas"
                      value={this.state.tipo_horas}
                      maxTagCount={5}
                      required={true}
                      onChange={this.handleSelectAllHora}>
                      {ListTipoHora}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["tipo_horas"]}</span>
                  </Form.Item>
                  <Form.Item label="Tipo de Tareas">
                    <Select
                      mode="multiple"
                      placeholder="Selecciona Tipo Tareas"
                      value={this.state.tipo_tareas}
                      maxTagCount={5}
                      required={true}
                      onChange={this.handleSelectAllTarea}>
                      {ListTipoTarea}
                    </Select>
                    <span style={{ color: "red" }}>{this.state.errors["tipo_tareas"]}</span>
                  </Form.Item>
                  <Form.Item label="Inicio de Jornada">
                    <Input
                      name="inicioJornada"
                      type="text"
                      className="center-block"
                      maxLength={6}
                      value={this.state.inicioJornada}
                      required={true}
                      onChange={this.handleChangeInput}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["inicioJornada"]}</span>
                  </Form.Item>
                  <Form.Item label="Duración de Jornada">
                    <Input
                      name="duracionJornada"
                      type="text"
                      className="center-block"
                      maxLength={3}
                      value={this.state.duracionJornada}
                      required={true}
                      onChange={this.handleChangeHour}
                    />
                    <span style={{ color: "red" }}>{this.state.errors["duracionJornada"]}</span>
                  </Form.Item>
                </div>
                : null}
            </Form>
            : null}
        </Modal>
        <Modal
          title="Seleccione Fecha"
          visible={this.state.pickDate}
          onCancel={this.closeDatePicker}
          width={350}
          footer={[
            <Button key="Finalizar" type="primary"
              onClick={this.manipulateDates}
            >
              Seleccionar
            </Button>
          ]}
        >
          <div>
            <DayPicker
              mode="single"
              selectedDays={this.state.fechaAlta}
              useWeekdaysShort={true}
              weekdaysShort={WEEKDAYS_SHORT['es']}
              months={MONTHS['es']}
              fromMonth={new Date(year, b_month)}
              // toMonth={new Date(year, b_month)}
              onDayClick={this.handleDayClick}
              disabledDays={disabledDays}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(ListEmployee);