import React from "react";
import { Card, Row, Col, Button, Modal, Input, Icon, Table, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

import { employee, selectInfoDash } from "routes/api/urls"
import { listar, get, post } from "routes/api/Llamadas"
import { connect } from "react-redux"
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const mapStateToProps = state => ({
    session: state.settings.session
});

class FichajesAdmin extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          usuario: {},
          employees: null,
          empleado_id: this.props.session.empleado_id
            ? this.props.session.empleado_id
            : "",
          dataSource : [],
          parcial: true
        };

      }
    componentDidMount() {
        this.renderData();
    }
    renderData = async () => {
        
        this.setState({ loading: true }); 
        const { auth_token, empleado_id } = this.props.session;
        let rec = [];
        let todo = await listar( employee, auth_token);
        let activos = [];
        todo.data.map(x => {
            
            activos.push({"id":x.id,  "nombreEmpleado":x.nombre});
            
          });
          
        //,
        //                 "Horas":this.state.horasProyect, "typehour":"Ord",
        //                 "typetask":"Seleccione", "subtarea":null,"observaciones":null,"tipoTareas":this.state.tareasProy, "tipoHoras":this.state.tipoHProy});
        this.setState({employees: activos});

    }
    render(){
        //if (!this.state.employees) return null;
        // if (!this.state.tipoProyectos) return null;
        // if(this.state.unauthorized) return null;
        const dashColumns = [
            {
              title: "Nombre",
              dataIndex: "nombreEmpleado",
              key: "nombreEmpleado"
            },
            {
              title: "Email",
              dataIndex: "emailCorp",
              key: "emailCorp"
            },
            {
              title: "Rol",
              dataIndex: "role",
              key: "role"
            },
            // {
            //   title: "Acciones",
            //   dataIndex: "id",
            //   key: "id",
            //   fixed: "right",
            //   render: (value, dataSource) => (
            //     <span>
            //       <Button onClick={() => this.test(dataSource.id)} type="danger" icon="delete" size="small" style={{ marginBottom: "0px"}}/>            
            //       <Button onClick={() => this.agregarTarea(dataSource.id)} type="primary" icon="search" size="small" style={{ marginBottom: "0px"}}/>
            //     </span>
            //   )
            // }
          ];
          
          console.log(this.props)
        return(
            
            <div>
                
                <Card className="gx-card" title="Empleados" style={{display: this.state.parcial ? "":"none"}} extra={
            <span>
              <Button onClick={this.renderData} type="link" style={{marginBottom: "0px", marginRight: "0px"}}>
                <i className="icon icon-shuffle gx-pointer gx-fs-xl gx-text-primary"/></Button>         
             {this.props.session.rol != "1" ? null :
              <Divider type="vertical"/>}
              {this.props.session.rol != "1" ? null :
              <ExcelFile filename="Fichajes" element={<Button style={{ marginBottom: 0 }} type="link" icon="file-excel" size="default">
      Exportar</Button>}>
            <ExcelSheet 
                data={this.state.fichajes} //levantar api
                name="Fichajes">       
                <ExcelColumn label="Empleado" value="nombreEmpleado"/>
                {/* <ExcelColumn label="Fecha" value={(col) => moment(col.fecha).format("DD/MM/YYYY")}/>
                <ExcelColumn label="Hora" value="hora"/>
                <ExcelColumn label="Tipo Fichaje" value="tipo_fichaje"/>
                <ExcelColumn label="Tiempo Acumulado" value={ (col) => {
                          let horasRealizadas = ""
                          if(col.tiempoAcumulado >= 60){
                              let horas = Math.floor(col.tiempoAcumulado / 60)
                              let minutos = Math.floor(col.tiempoAcumulado % 60)
                              horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                          } else if(col.tiempoAcumulado <= -60){
                              let horas = Math.ceil(col.tiempoAcumulado / 60)
                              let minutos = Math.floor(col.tiempoAcumulado % 60)
                              horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                          } else {
                              horasRealizadas = Math.floor( col.tiempoAcumulado) +" minutos"
                          }
                      return horasRealizadas
                        }
                  }/> */}

                {/* <ExcelColumn label="observaciones" value="Observaciones"/> */}
            </ExcelSheet>
        </ExcelFile>}
              </span>}>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={this.state.employees} 
                  columns={dashColumns}
                  pagination={true} 

                />
                </Card>
            </div>

        );
    }
}

export default connect(mapStateToProps, {})(FichajesAdmin);