import React from "react";
import { Card, Row, Col, Button, Modal, Input, Icon, Table, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

import { /*getFichajeWithData,  selectFichajeWithData,*/ deleteFichaje} from "routes/api/urls"
import {  /*listarPrivado,*/ post } from "routes/api/Llamadas"
import { connect } from "react-redux"
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const mapStateToProps = state => {
  return {
    session: state.settings.session,
  }
}
const confirm = Modal.confirm;

class Fichajes extends React.Component{
  constructor(props){
    //debugger;
    super(props)
    this.state={
      fichajes: this.props.dataFichaje,

      sortedInfo: null,
      filteredInfo: null,
      searchText: '',
    }

    this.renderData = this.renderData.bind(this)
  }

  async componentDidMount(){
    this.renderData()
  }
  async componentDidUpdate(){
    //debugger;
  }
  async renderData(){
    try{
      //debugger;
      let activos = []
      let reversed = []
      //let token = this.props.session.auth_token
      // if(this.props.empleado_id !== 0){
      /*let data = {
        empleado_id : this.props.empleado_id
      }*/
      activos = this.props.dataFichaje; // post(data, getFichajeWithData, token)
      if(activos){
        if( activos.message === "Unauthenticated."){
          localStorage.clear();
          this.props.session.isLoggedIn = true;
          this.props.session.auth_token = null;
          window.location.href = "/signin"
    
        }
      // } else {
      //   let todos = await listarPrivado(selectFichajeWithData, token)
      //   todos.data.map( x => {
      //     if(x.isActive === 1){
      //       activos.push(x)
      //     }
      //   })
      // }

        reversed = activos.reverse();
      }
      this.setState({
        fichajes: reversed
      })
    } catch(Error) { console.error(Error); }
  }
  async eliminarRegistro(key){
    try{
      let token = this.props.session.auth_token
      await post(key, deleteFichaje,token)
      this.renderData()
      
    }catch(Error){ console.error(Error); }
  }
  showDeleteConfirm=(id)=>{
    let key = { 
      id: id 
    }
    let thisState = this

    confirm({
      title: 'Esta seguro que desea continuar?',
      content: 'Esta acción eliminará el registro.',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        //console.log('Se elimina el registro ', id);
        thisState.eliminarRegistro(key)
    },
      onCancel() {
        //console.log('Cancel', id);
      },
    });
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text != null ? text.toString() : ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChangeTabla = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
    render(){
      if(!this.state.fichajes) return null
      this.state.fichajes =  this.props.dataFichaje.reverse();
      //const handleClick = e => this.showDeleteConfirm(e.target.id)
      let { sortedInfo, filteredInfo } = this.state;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};

     const fichajeColumns = [
        // {
        //     title: 'Empleado',
        //     dataIndex: 'nombreEmpleado',
        //     key: 'nombreEmpleado',
        //     ...this.getColumnSearchProps('nombreEmpleado'),
        //   },
          {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            sorter: (a, b) => moment(a.fecha, 'YYYY/MM/DD') - moment(b.fecha, 'YYYY/MM/DD'),
            sortOrder: sortedInfo.columnKey === 'fecha' && sortedInfo.order,
          },
          {
            title: 'Hora',
            dataIndex: 'hora',
            key: 'hora',
            ...this.getColumnSearchProps('hora'),
          },
          {
            title: 'Tipo Fichaje',
            dataIndex: 'tipo_fichaje',
            key: 'tipo_fichaje',
            filters: [
              {
                  text: 'Inicio de Jornada',
                  value: 'Inicio de Jornada',
              },
              {
                text: 'Inicio de Pausa',
                value: 'Inicio de Pausa',
              },
              {
                  text: 'Fin de Pausa',
                  value: 'Fin de Pausa',
              },
              {
                text: 'Fin de Jornada',
                value: 'Fin de Jornada',
              },
            ],
            onFilter: (value, record) => record.tipo_fichaje ? record.tipo_fichaje.toString().indexOf(value) === 0 : null,
        
          },
          {
            title: 'T.Ordinario Acumulado',
            dataIndex: 'tiempoAcumulado',
            key: 'tiempoAcumulado',
            render: (text, record) => {
                let horasRealizadas = ""
                if(record.tiempoAcumulado >= 60){
                    let horas = Math.floor(record.tiempoAcumulado / 60)
                    let minutos = Math.floor(record.tiempoAcumulado % 60)
                    //console.log("record.tiempoAcumulado: ", record.tiempoAcumulado)
                    //console.log("hora: ", horas, ":", minutos)
                    horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                } else if(record.tiempoAcumulado <= -60){
                  //console.log("record.tiempoAcumulado / 60", record.tiempoAcumulado / 60)
                    let horas = Math.ceil(record.tiempoAcumulado / 60)
                    let minutos = Math.floor(record.tiempoAcumulado % 60)
                    //console.log("record.tiempoAcumulado: ", record.tiempoAcumulado)
                    //console.log("hora: ", horas, ":", minutos)
                    horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                } else {
                    horasRealizadas = Math.floor( record.tiempoAcumulado) +" minutos"
                }
            return <span style={{ color: record.tiempoAcumulado >= 0 ? "green" : "red"}}>{horasRealizadas}</span>
            }
          },
          // {
          //   title: 'Observaciones',
          //   dataIndex: 'observaciones',
          //   key: 'observaciones',
          // },
        ];
        //debugger;
        return(
          
          <Row>
          <Col span={24}>
            <Card title="Fichaje Actual" extra={
            <span>
              <Button onClick={this.renderData} type="link" style={{marginBottom: "0px", marginRight: "0px"}}>
                <i className="icon icon-shuffle gx-pointer gx-fs-xl gx-text-primary"/></Button>         
             {this.props.contexto !== "RRHH" ? null :
              <Divider type="vertical"/>}
              {this.props.contexto !== "RRHH" ? null :
              <ExcelFile filename="Fichajes" element={<Button style={{ marginBottom: 0 }} type="link" icon="file-excel" size="default">
      Exportar</Button>}>
            <ExcelSheet 
                data={this.state.fichajes} 
                name="Fichajes">       
                <ExcelColumn label="Empleado" value="nombreEmpleado"/>
                <ExcelColumn label="Fecha" value={(col) => moment(col.fecha).format("DD/MM/YYYY")}/>
                <ExcelColumn label="Hora" value="hora"/>
                <ExcelColumn label="Tipo Fichaje" value="tipo_fichaje"/>
                <ExcelColumn label="Tiempo Acumulado" value={ (col) => {
                          let horasRealizadas = ""
                          if(col.tiempoAcumulado >= 60){
                              let horas = Math.floor(col.tiempoAcumulado / 60)
                              let minutos = Math.floor(col.tiempoAcumulado % 60)
                              horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                          } else if(col.tiempoAcumulado <= -60){
                              let horas = Math.ceil(col.tiempoAcumulado / 60)
                              let minutos = Math.floor(col.tiempoAcumulado % 60)
                              horasRealizadas = horas+ " horas, "+ minutos +" minutos"
                          } else {
                              horasRealizadas = Math.floor( col.tiempoAcumulado) +" minutos"
                          }
                      return horasRealizadas
                        }
                  }/>

                <ExcelColumn label="observaciones" value="Observaciones"/>
            </ExcelSheet>
        </ExcelFile>}
              </span>}>  
              <Table
                  rowKey="id"
                  size="small"
                  dataSource={this.state.fichajes} 
                  columns={fichajeColumns} 
                  onChange={this.handleChangeTabla}
                  //scroll={{ x: 500}}
              />
            </Card> 
          </Col>
        </Row>
        )
    }
}

export default connect(mapStateToProps, {})(Fichajes);


