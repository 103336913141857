import React from "react";
//import {Avatar} from "antd";
import {Link} from "react-router-dom";

const ProfileHeader = (props) => {
  debugger;
  return (
    
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            {/*<div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt="..." />
            </div>
             <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{props.usuario.data.nombre} {props.usuario.data.primerApellido} {props.usuario.data.segundoApellido}</h2>
              {/*<p className="gx-mb-0 gx-fs-lg">{props.data.provincia}, {props.data.pais}</p>
            </div> */}
          </div>

        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <li>
                <span className="gx-link"><Link to={{
                pathname: "/empleado/perfil",
                state: {
                    titulo: "Perfil",
                    empleado: props.usuario.id,
                }
              }}>Perfil</Link></span>
              </li>
            </ul>
              {/*
            
          </div>
          
           <span className="gx-link gx-profile-setting">
              <span className="gx-link gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
                  <Link style={{ color: "#9bab1f"}} to={{
                    pathname: "/empleado/ajustes",
                    state: {
                        titulo: "Ajustes",
                        empleado: props.usuario.id,
                    }
                  }}>
                  <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle"/>
                    Ajustes</Link> 
              </span>
          </span>*/}
          
        </div>
      </div>
    </div>
    </div>
  )
}

export default ProfileHeader;
