import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "constants/ActionTypes";

//MY_ACTION_TYPES
import { RECUENTO_HORA_DATA_LOADED, INFORME_AUSENCIA_DATA_LOADED, INFORME_BAJA_DATA_LOADED, DOCUMENTOS_DATA_LOADED, BOLSA_COMPENSACIONES_DATA_LOADED, VACACIONES_DATA_LOADED, ADD_FOTO, ADD_ARTICLE, ADD_SESSION, ADD_EMPLEADOS, EMPLEADOS_DATA_LOADED, USER_DATA_LOADED, LOGIN_LOADED } from "../constants/action-types";

import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  colorSelection: THEME_COLOR_SELECTION_PRESET,

  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },

  //  MY_INITIAL_SETTINGS
  articles: [],
  empleados: [],
  remoteArticles: [],
  usuarios: [],
  session: {},
  foto:{},
  vacaciones : [],
  bolsaCompensaciones : [],
  documentos : [],
  informesBaja : [],
  informesAusencia: [],
  recuentoHoras: [],
};

const settings = (state = initialSettings, action) => {
  //console.log("REDUCER ACTION : ", action)
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.pathname,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR_SELECTION:
      return {
        ...state,
        colorSelection: action.colorSelection
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };

//----------------------- MY_REDUCERS ---------------------
      case ADD_ARTICLE:
          return Object.assign({}, state, {
            articles: state.articles.concat(action.payload)
          });
      case ADD_EMPLEADOS:
        return Object.assign({}, state, {
          //empleados: state.empleados.concat(action.payload)
        });
      case EMPLEADOS_DATA_LOADED:
        //console.log("EMPLEADOS_DATA_LOADED - action.payload : ", action.payload)
        return Object.assign({}, state, {
          empleados: action.payload
          //remoteArticles: state.remoteArticles.concat(action.payload)
        });
      case VACACIONES_DATA_LOADED:
        return Object.assign({}, state, {
          vacaciones: action.payload.vacaciones
        });
      case BOLSA_COMPENSACIONES_DATA_LOADED:
        return Object.assign({}, state, {
          bolsaCompensaciones: action.payload
        });
      case INFORME_BAJA_DATA_LOADED:
        return Object.assign({}, state, {
          informesBaja: action.payload
        });
      case INFORME_AUSENCIA_DATA_LOADED:
        return Object.assign({}, state, {
          informesAusencia: action.payload
        });
      case USER_DATA_LOADED:
        return Object.assign({}, state, {
          usuarios: action.payload
        });
      case ADD_SESSION:
        return Object.assign({}, state, {
          session: action.payload
        });
      case ADD_FOTO:
        return Object.assign({}, state, {
          foto: action.payload
        });
      case DOCUMENTOS_DATA_LOADED:
        return Object.assign({}, state, {
          documentos: action.payload.data
        });
      case RECUENTO_HORA_DATA_LOADED:
        return Object.assign({}, state, {
          recuentoHoras: action.payload
        });
    default:
      return state;
  }
};

export default settings;

