import React from "react";
import { Input, Row, Col, Form, Button, Card, Alert, message  } from 'antd';
import FormItem from "antd/lib/form/FormItem";
import { updatePassword, updateConfig } from "routes/api/urls"
import { post } from "routes/api/Llamadas"


const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 14},
  },
};

class PerfilForm extends React.Component{
  constructor(props){

    super(props)
    this.state = {
        id: "",
        empleado_id: "",

        isActive: 1, 
        fichajes: null,
        newPw: "",
        currentPw: "",
        confirmPw: "",
        errorPass : false,
        errorPassConfirm: false,
        inicioJornada: this.props.session.inicioJornada
    }
  }
  async componentDidMount(){
   debugger;
    try{
      //const { auth_token, empleado_id } = this.props.session;
      const probando = this.props.session;
      //let resp = await post(data, url, auth_token)
     
    } catch(Error) { console.error(Error); }
  }



  handleChange = (event) => {
    let name = event.target.name;
    if(name === 'newPw'){
      if(this.state.confirmPw){
        let error = true;
        if(event.target.value === this.state.confirmPw) error = false ;
        if (error){
          this.setState({errorPassConfirm: true})
        }else{
          this.setState({errorPassConfirm: false})
        }
      }
    }
    this.setState({ [name]: event.target.value })
  }
  handleChangeConfirm = (event) => {
    
    let name = event.target.name;
    let value = event.target.value;
    
    if(this.state.newPw === value){
      this.setState({ [name]: value, errorPassConfirm: false  })
    }else{
      this.setState({ [name]: value , errorPassConfirm: true })
    }
  }
  

  async save(data, url){
    try{
      const { auth_token } = this.props.session;
      let resp = await post(data, url, auth_token)
      console.log(resp)
      if(resp.success){
        this.success(resp.message);
        if(resp.data.horaInicio){
          
          window.localStorage.setItem('inicioJornada', resp.data.horaInicio);
        }
        this.setState({ errorPass: false, newPw: "", currentPw: "", confirmPw: ""})
      }else{
        resp.data.error === "Su password es Incorrecto" ?  this.setState({ errorPass: true }) : console.log(resp.data.error);
      }
      debugger;
    } catch(Error) {
        console.error(Error);
    }
  }
  success = (data) => {
    message.success(`${data}`, 1);
  };
 


update = ()  => {
  const {empleado_id } = this.props.session;
  const { currentPw, confirmPw, newPw, errorPassConfirm } = this.state;
  if (!errorPassConfirm ){
    let data = {
      empleado_id,
      newPw,
      currentPw,
      confirmPw
    };
    this.save(data, updatePassword)
  }
}
updateConfig = ()  => {
  debugger;
  const {empleado_id } = this.props.session;
  const { inicioJornada } = this.state;
  this.props.session.inicioJornada = inicioJornada;
  let data = {
    empleado_id,
    inicioJornada
  };
  this.save(data, updateConfig)

}

validar = (data) => {
  let bandera = false
//ver
  return bandera
}


handleSubmit = (e) =>{
  e.preventDefault();
  this.update();
}
handleSubmitConfig = (e) => {
  e.preventDefault();
  this.updateConfig();
}
handleCancel = (e) =>{
  e.preventDefault();
  this.props.history.goBack()
}
    render(){
        return(
          <div>
              <Card title={"Contraseña"} >
                  <Row>
                      <Col span={20} offset={2}>
                          <Form onSubmit={this.handleSubmit}>
                              <FormItem {...formItemLayout} label="Contraseña Actual">
                                  <Input name="currentPw" placeholder="" required={true}  value={this.state.currentPw} type="text" onChange={this.handleChange} />
                                  {
                                      this.state.errorPass ? 
                                      <Alert className="gx-mt-2" message="La contraseña es incorrecta" type="error" showIcon />
                                      : null
                                  }
                              </FormItem>	 
                              <FormItem {...formItemLayout} label="Nueva Contraseña">
                                  <Input name="newPw" placeholder="" required={true}  value={this.state.newPw} type="text" onChange={this.handleChange} />
                              </FormItem>	 
                              <FormItem {...formItemLayout} label="Repita Contraseña">
                                  <Input name="confirmPw" placeholder="" required={true}  value={this.state.confirmPw} type="text" onChange={this.handleChangeConfirm} />
                                  {
                                      this.state.errorPassConfirm ?     
                                      <Alert className="gx-mt-2" message="La contraseña no coincide" type="error" showIcon />
                                      : null
                                  }
                              </FormItem>

                              <Col span={16} offset={8} className="gx-text-left">
                              <Button onClick={this.handleCancel} type="default" htmlType="button">Cancelar</Button>

                                  <Button type="primary" htmlType="submit">Guardar</Button>
                              </Col>
                          </Form>
                      </Col>
                  </Row>
              </Card>
              <Card title={"Configuraciones"} >
              <Row>
                  <Col span={20} offset={2}>
                      <Form onSubmit={this.handleSubmitConfig}>
                          <FormItem {...formItemLayout} label="Hora Entrada">
                              <Input name="inicioJornada" placeholder="" required={true}  value={this.state.inicioJornada} type="text" onChange={this.handleChange} />
                              
                          </FormItem>	 
                          

                          <Col span={16} offset={8} className="gx-text-left">
                          <Button onClick={this.handleCancel} type="default" htmlType="button">Cancelar</Button>

                              <Button type="primary" htmlType="submit">Guardar</Button>
                          </Col>
                      </Form>
                  </Col>
              </Row>
            </Card>
        </div>
        )
    }
}

export default PerfilForm;


