import React from "react";

import {Button, Form, Input, Alert, notification, Spin } from "antd";
import logo from '../assets/logos/logo_ioon_2020.png';
import {post} from 'routes/api/Llamadas.js'
import {reset} from 'routes/api/urls.js'
const FormItem = Form.Item;
const openNotification = () => {
  notification.success({
    duration: 0,
    style: {
      width: 600,
      marginLeft: 335 - 600,
    },
    message: 'Solicitud procesada con éxito',
    description:
      'Se ha enviado un mensaje a su correo con el código de restauracion.',
    onClick: () => {
      //console.log('Notification Clicked!');
    },
  });
};
class RecuperarPass extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email:"",

      validacion: false,
      mensaje: "",

      loading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);

  }
    async handleSubmit(e){
        e.preventDefault();
        //console.log("email: ", this.state.email)
        //this.props.history.push("/signin");
        try{
          this.setState({ loading: true})
          let data = {
            email: this.state.email
          }
          //let token = this.props.session.auth_token
          let resp = await post(data, reset) 
          //console.log(resp)
          if(resp.success){ 
            this.setState({ loading: false})
            openNotification()
            this.props.history.push("/signin");
          } else {
            this.setState({
              loading: false,
              validacion: true,
              mensaje: resp.message
            })
          }

        } catch(Error) { console.error(Error); }
      };
      handleChange = (event) => {
        let name = event.target.name;
        this.setState({ [name]: event.target.value })
        this.setState({
          validacion: false,
          mensaje: ""
        })
      }
      handleCancel = (e) =>{
        e.preventDefault();
        this.props.history.push("/signin");
      }
    render(){
      
        return(
          <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
            <Spin spinning={this.state.loading}>

              <div className="gx-app-login-main-content">

                <div className="gx-app-logo-content">
                  
                  <div style={{ textAlign: "center" }} className="gx-app-logo">
                    <img style={{ width: "100%" }} src={logo} alt='Logo'/>
                  </div>
               
                  
                  
                </div>
                <div className="gx-app-login-content">
                <h1>Recuperar Contraseña</h1>
                <span>Introduce tu email</span>
                  <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                    <FormItem>   
                        <Input name="email" placeholder="Email" required={true}  
                        value={this.state.email} type="email" 
                        onChange={this.handleChange}/>
                    </FormItem>
                    {
                      this.state.validacion ? 
                      <Alert
                         message={this.state.mensaje}
                         //description="This is an error message about copywriting."
                         type="error"
                         showIcon
                         className="gx-mb-3"
                       />
                      : null
                    }
                    <FormItem>
                      <Button type="primary" className="gx-mb-0" htmlType="submit">
                        Enviar
                        
                      </Button> 
                      <Button onClick={this.handleCancel} type="link" className="gx-mb-0" htmlType="button">Cancelar</Button>

                    </FormItem>
                  </Form>
                  
                </div>
    
              </div>
            </Spin> 
            </div>
          </div>  
               
          )
    }
}

export default RecuperarPass