import { Row, Spin, Col, Card, Button, Icon, Table, Modal, Form, Input, Select, } from "antd";
import React from "react";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { post, get, put } from "routes/api/Llamadas";
import { addClient, getAllClients, getClientById, updateClient,  provincias, } from 'routes/api/urls';

const { Option } = Select

const mapStateToProps = state => ({
    session: state.settings.session
})

class Clients extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            loading: true,
            visible: false,
            visibleEdit: false,
            visibleAdd: false,
            visibleDisable: false,
            titleModal: "Agregar Cliente",
            clientName: "",
            disabledSave: false,
            flagEdit: false,
            idClientEdit: 0,
            fields: {
                nombre: '',
                nombreLegal: '',
                tipoIdentificacion: null,
                identificacion: '',
                ciudad: '',
                provincia: '',
                pais: '',
                email: '',
                telefono: '',
                direccion: '',
                cp: '',
                numeroIdentificacion: '',

            },
            errors: {},
            dataSourceCalendar: []


        }
    }

    async componentDidMount() {
        this.setState({loading:true})
        await this.getClient();
        await this.getPronvincias();
    }
    getPronvincias = async ()=>{
        const { auth_token } = this.props.session;
        let resp = await get(provincias, auth_token);
        if(resp.data.length>0){
            this.setState({dataSourceCalendar: resp.data});
            console.log('data--->', resp.data)
        }
        this.setState({loading:false})
    }
    addClient = () => {
        this.setState({
            visible: true,
            titleModal: 'Añadir Cliente',
            visibleEdit: false,
            visibleDisable: false,
            visibleAdd: true
        });
    }
    getClient = async () => {
        const { auth_token } = this.props.session;
        this.setState({ loading: true });
        let resp = await get(getAllClients, auth_token);

        if (resp.data.length > 0) {
            this.setState({
                dataSource: resp.data
            });
            this.setState({ loading: false });
        }

    }

    disableClient = (idClient, nameClient) => {
        this.setState({
            visible: true,
            clientName: nameClient,
            titleModal: 'Dar de baja Cliente',
            visibleEdit: false,
            visibleDisable: true,
            visibleAdd: false
        });
    }

    editClient = (idClient) => {
        this.setState({
            titleModal: 'Editar Cliente',
            // visible: true,
            // visibleEdit: true,
            visibleDisable: false,
            visibleAdd: true,
            flagEdit: true,
            idClientEdit: idClient,
            loading: true
        })
        this.getClientById(idClient);
    }

    handleOk = () => {
        console.log('push seguido')
        let data = {
            nombre: this.state.nombre,
            nombreLegal: this.state.nombreLegal,
            tipoIdentificacion: this.state.tipoIdentificacion,
            numeroIdentificacion: this.state.numeroIdentificacion,
            ciudad: this.state.ciudad,
            provincia: this.state.provincia,
            pais: this.state.pais,
            email: this.state.email,
            telefono: this.state.telefono,
            cp: this.state.cp,
            direccion: this.state.direccion
        }

        if (this.handleValidation()) {

            this.save(data, addClient);
        }
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: false
        });
        this.handleResetForm();
    }
    handleChangeInput = (event) => {
        let fields_ = this.state.fields;

        let name = event.target.name;
        fields_[name] = event.target.value;

        this.setState({ [name]: event.target.value, fields: fields_ })

        // console.log(fields_);
    }
    handleSelectChangeIdent = (value) => {
        let fields_ = this.state.fields;
        fields_['tipoIdentificacion'] = value;

        this.setState({
            tipoIdentificacion: value,
            fields: fields_
        });
    }


    handleResetForm = () => {
        //Resetear el formulario
        this.setState({
            nombre: '',
            nombreLegal: '',
            tipoIdentificacion: null,
            numeroIdentificacion: '',
            ciudad: '',
            provincia: '',
            pais: '',
            email: '',
            telefono: '',
            direccion: '',
            cp: '',
        })
    }

    async save(data, url) {
        try {
            let token = this.props.session.auth_token;
            let resp = {};
            if (this.state.flagEdit) {
                data['id'] = this.state.idClientEdit;
                
                resp = await put(data, updateClient, token)
            } else {
                resp = await post(data, url, token);
            }


        } catch (Error) {
            console.log('error', Error)
        }
        await this.getClient();
        this.setState({ visible: false, flagEdit: false });
        this.handleResetForm()
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {}
        let formIsValid = true;
        //Nombre
        if (!fields["nombre"]) {
            formIsValid = false;
            errors["nombre"] = "No puede ser vacío";
        }


        //Nombre legal
        if (!fields["nombreLegal"]) {
            formIsValid = false;
            errors["nombre"] = "No puede ser vacío";
        }


        //         tipoIdentificacion
        if (!fields["tipoIdentificacion"]) {
            formIsValid = false;
            errors["tipoIdentificacion"] = "Debe seleccionar uno";
        }

        //Identificacion
        if (!fields["numeroIdentificacion"]) {
            formIsValid = false;
            errors["numeroIdentificacion"] = "No puede ser vacío";
        }

        //         ciudad
        if (!fields["ciudad"]) {
            formIsValid = false;
            errors["ciudad"] = "No puede ser vacío";
        }

        //         provincia
        if (!fields["provincia"]) {
            formIsValid = false;
            errors["provincia"] = "No puede ser vacío";

        }

        //         pais: '',
        if (!fields["pais"]) {
            formIsValid = false;
            errors["pais"] = "No puede ser vacío";

        }

        //         email: '',
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "No puede ser vacío";
        }
        let reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let letOkEmailCorp = reEmail.exec(fields["email"]);
        if (!letOkEmailCorp) {
            formIsValid = false;
            errors["email"] = "No es un email válido";
        }

        //         telefono
        if (!fields["telefono"]) {
            formIsValid = false;
            errors["telefono"] = "No puede ser vacío"
        }
        let reNumeros = /^([0-9])*$/
        let letOkNumero = reNumeros.exec(fields["telefono"]);
        if (!letOkNumero) {
            formIsValid = false;
            errors["telefono"] = "Solo se permiten números";
        }

        //         direccion: '',
        if (!fields["direccion"]) {
            formIsValid = false;
            errors["direccion"] = "No puede ser vacío";
        }
        //         cp: '',
        if (!fields["cp"]) {
            formIsValid = false;
            errors["cp"] = "No puede ser vacío";
        }

        let letOkCP = reNumeros.exec(fields["cp"]);
        if (!letOkCP) {
            formIsValid = false;
            errors["cp"] = "Solo se permiten números";
        }



        this.setState({ errors: errors });
        return formIsValid;
    }


    getClientById = async (idClient) => {
        const { auth_token } = this.props.session;
        try {
            let resp = await get(getClientById + idClient, auth_token);

            let dataClient = JSON.parse(JSON.stringify(resp.data))

            this.setState({
                ciudad: dataClient.ciudad,
                cp: dataClient.cp,
                nombre: dataClient.nombre,
                nombreLegal: dataClient.nombreLegal,
                tipoIdentificacion: dataClient.tipoIdentificacion,
                numeroIdentificacion: dataClient.numeroIdentificacion,
                direccion: dataClient.direccion,
                provincia: dataClient.provincia,
                pais: dataClient.pais,
                email: dataClient.email,
                telefono: dataClient.telefono,
                visible: true,
                fields: {
                    ciudad: dataClient.ciudad,
                    cp: dataClient.cp,
                    nombre: dataClient.nombre,
                    nombreLegal: dataClient.nombreLegal,
                    tipoIdentificacion: dataClient.tipoIdentificacion,
                    numeroIdentificacion: dataClient.numeroIdentificacion,
                    direccion: dataClient.direccion,
                    provincia: dataClient.provincia,
                    pais: dataClient.pais,
                    email: dataClient.email,
                    telefono: dataClient.telefono,
                }
            })

        } catch (error) {
            console.log('errror llamada')
        }

    }
    handleSelectChangeCalendario = (value) => {
        let fields_ = this.state.fields;
        fields_['provincia'] = value;
    
        this.setState({
          provincia: value,
          fields: fields_
        });
      }

    render() {
        const dashColumns = [
            {
                title: "Id",
                dataIndex: "id",
                key: "Id",
            }, {
                title: "Nombre",
                dataIndex: "nombre",
                key: "nombre",
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                    return (
                      <>
                        <Input autoFocus placeholder="Escribe el nombre"
                          onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                          }}
                          value={selectedKeys[0]}
                          onPressEnter={() => {
                            confirm();
                          }}
                          onBlur={() => {
                            confirm();
                          }}
                        ></Input>
                        <div>
                          <Button style={{ marginBottom: '0px' }} onClick={() => {
                            confirm()
                          }}
                            type="primary">Buscar</Button>
                          <Button style={{ marginBottom: '0px' }} onClick={() => {
                            clearFilters();
                          }}
                            type="danger">Resetear</Button>
                        </div>
                      </>
                    )},
                    filterIcon: () => {
                        return <SearchOutlined />
                      },
                      onFilter: (value, record) => {
                        if(!record.nombre){
                          return false;
                        }
                        if(value.trim()=='') return true;
                        return record.nombre.toLowerCase().trim().includes(value.toLowerCase().trim())
                      }
            },
            {
                title: "Nombre Legal",
                dataIndex: "nombreLegal",
                key: "nombreLegal",
            },
            {
                title: "Número Identificación",
                dataIndex: "Número Identificación",
                key: "numeroIdentificacion",
            },
            // {
            //     title: "País",
            //     dataIndex: "pais",
            //     key: "pais",
            // },
            // {
            //     title: "Provincia",
            //     dataIndex: "provincia",
            //     key: "provincia",
            // },
            {
                title: "Teléfono",
                dataIndex: "telefono",
                key: "telefono",
            },
            // {
            //     title: "Tipo de Identificación",
            //     dataIndex: "tipoIdentificacion",
            //     key: "tipoIdentificacion",
            // },
            // {
            //     title: "Ciudad",
            //     dataIndex: "ciudad",
            //     key: "ciudad",
            // },
            // {
            //     title: "CP",
            //     dataIndex: "cp",
            //     key: "cp",
            // },
            // {
            //     title: "Direccion",
            //     dataIndex: "direccion",
            //     key: "direccion",
            // },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
            }, {
                title: "Acciones",
                dataIndex: "id",
                key: "id",
                fixed: "right",
                render: (value, dataSource) => (
                    <span>
                        {/* <Button onClick={() => this.disableClient(dataSource.id, dataSource.nombre)} type="danger" icon="delete" size="small" style={{ marginBottom: "0px" }} /> */}
                        <Button onClick={() => this.editClient(dataSource.id)} type="primary" icon="edit" size="small" style={{ marginBottom: "0px" }} />
                    </span>
                )
            }
        ]

        const { loading } = this.state;
        const ListProvincia = this.state.dataSourceCalendar ? this.state.dataSourceCalendar.map(x => <Option key={x.id} id={x.id} value={x.nombre} >{x.nombre}</Option>) : null
        return (
            <div>
                <Spin spinning={loading}>
                    <Row>
                        <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="gx-order-sm-1">
                            <Card title="Clientes" className="gx-card" extra={
                                <span>
                                    <Button block type="primary" onClick={this.addClient} style={{ marginBottom: "0px", marginRight: "0px" }}>
                                        <Icon type="plus" />
                                        Agregar Cliente
                                    </Button>
                                </span>
                            }>
                                <Table
                                    rowKey="id"
                                    size="small"
                                    dataSource={this.state.dataSource}
                                    columns={dashColumns}
                                    pagination={true}>
                                    scroll={{ x: true }}
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Spin>
                <Modal
                    title={this.state.titleModal}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={800}
                    maskClosable={true}
                    footer={[
                        <Button key="Confirmar" type="primary" disabled={this.state.disabledSave} onClick={this.handleOk}>
                            Guardar
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                            Cancelar
                        </Button>
                    ]}
                >
                    {
                        this.state.visibleDisable == true ? <div>
                            ¿Está seguro que desea dar de baja al cliente {this.state.clientName}?
                        </div> : null
                    }
                    {
                        this.state.visibleEdit == true ? <p>EDITAR</p> : null
                    }
                    {
                        this.state.visibleAdd == true ? <div>

                            <Form layout="vertical">
                                <Form.Item label="Nombre">
                                    <Input
                                        name="nombre"
                                        type="text"
                                        className="center-block"
                                        value={this.state.nombre}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["nombre"]}</span>
                                </Form.Item>
                                <Form.Item label="Nombre Legal">
                                    <Input
                                        name="nombreLegal"
                                        type="text"
                                        className="center-block"
                                        value={this.state.nombreLegal}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["nombreLegal"]}</span>
                                </Form.Item>
                                <Form.Item label="Tipo Identificación">
                                    <Select
                                        value={this.state.tipoIdentificacion}
                                        placeholder={"Seleccione Tipo"}
                                        required={true}
                                        onChange={this.handleSelectChangeIdent}>
                                        <Option value="NIF">NIF</Option>
                                        <Option value="DNI">DNI</Option>
                                        <Option value="NIE">NIE</Option>

                                    </Select>
                                    <span style={{ color: "red" }}>{this.state.errors["tipoIdentificacion"]}</span>
                                </Form.Item>
                                <Form.Item label="Nº de Identificación">
                                    <Input
                                        name="numeroIdentificacion"
                                        type="text"
                                        className="center-block"
                                        value={this.state.numeroIdentificacion}
                                        maxLength={10}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["numeroIdentificacion"]}</span>
                                </Form.Item>
                                <Form.Item label="Dirección">
                                    <Input
                                        name="direccion"
                                        type="text"
                                        className="center-block"
                                        value={this.state.direccion}
                                        maxLength={20}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["direccion"]}</span>
                                </Form.Item>
                                <Form.Item label="Código Postal">
                                    <Input
                                        name="cp"
                                        type="text"
                                        className="center-block"
                                        value={this.state.cp}
                                        maxLength={20}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["cp"]}</span>
                                </Form.Item>
                                <Form.Item label="Ciudad">
                                    <Input
                                        name="ciudad"
                                        type="text"
                                        className="center-block"
                                        value={this.state.ciudad}
                                        maxLength={20}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["ciudad"]}</span>
                                </Form.Item>
                                <Form.Item label="Provincia">
                                    <Select
                                    value={this.state.provincia}
                                    placeholder={'Seleccione Provincia'}
                                    required={true}
                                    style={{ color: "black" }} onChange={this.handleSelectChangeCalendario}>
                                    {ListProvincia}
                                    </Select>
                                    <span style={{ color: "red" }}>{this.state.errors["provincia"]}</span>
                                </Form.Item>
                                <Form.Item label="País">
                                    <Input
                                        name="pais"
                                        type="text"
                                        className="center-block"
                                        value={this.state.pais}
                                        maxLength={20}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["pais"]}</span>
                                </Form.Item>
                                <Form.Item label="Email">
                                    <Input
                                        name="email"
                                        type="text"
                                        className="center-block"
                                        value={this.state.email}
                                        maxLength={100}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                </Form.Item>
                                <Form.Item label="Teléfono">
                                    <Input
                                        name="telefono"
                                        type="text"
                                        className="center-block"
                                        value={this.state.telefono}
                                        maxLength={9}
                                        required={true}
                                        onChange={this.handleChangeInput}
                                    />
                                    <span style={{ color: "red" }}>{this.state.errors["telefono"]}</span>
                                </Form.Item>
                            </Form>
                        </div> : null
                    }
                </Modal>
            </div>
        )
    }
}
export default connect(mapStateToProps, {})(Clients)