import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider"; 
import MainApp from "./MainApp";
import SignInProvisorio from "../SignInProvisorio";
import Logout from "../Logout";
import RecuperarPass from "../RecuperarPass";
//import EmpleadoDash from "../../routes/Empleado/Dashboard/index"
//import { ProtectedRoute } from "../../protectedRoute";

import {
  logout,
  registrarUsuario,
  getEmpleado
} from "routes/api/urls";
import { post } from "routes/api/Llamadas";

import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";
import { addSession } from "appRedux/actions/index";

import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";

function mapDispatchToProps(dispatch) {
  return {
    addSession: session => dispatch(addSession(session))
  };
}

class App extends Component {

  constructor(props) {
    debugger;
    super(props);
    this.state = {
      isLoggedIn: this.props.session.isLoggedIn,
      user_email: "",
      user_id: "",
      rol_id: "",
      auth_token: {},
      loading: true
    };
  }

  async setLocalStorage(appState) {
    //console.log("setLocalStorage token: ", appState.auth_token)
    try {

      // empleado = null;
      let rol = null;
      window.localStorage.setItem("user_id", this.props.session.user_id);
      window.localStorage.setItem("user_email", this.props.session.user_email);
      window.localStorage.setItem("empleado_id",this.props.session.empleado_id);
      window.localStorage.setItem("rol", this.props.session.rol);
      window.localStorage.setItem("auth_token", this.props.session.auth_token);
      window.localStorage.setItem("isLoggedIn", this.props.session.isLoggedIn);
      window.localStorage.setItem("empleado_nombre",this.props.session.empleado_nombre);
      window.localStorage.setItem("empleado_primerApellido",this.props.session.empleado_primerApellido);
      window.localStorage.setItem("duracionJornada",this.props.session.duracionJornada)
      window.localStorage.setItem("inicioJornada",this.props.session.inicioJornada)

      this.setState({
        //isLoggedIn: appState.isLoggedIn,
        isLoggedIn: this.props.session.isLoggedIn,
        user_email: this.props.session.user_email,
        auth_token: this.props.session.auth_token,
        user_id: this.props.session.user_id,
        rol_id: rol,
        empleado_nombre: this.props.session.user_name,
        empleado_primerApellido: this.props.session.empleado_primerApellido,
        duracionJornada: this.props.session.duracionJornada,
        inicioJornada: this.props.session.inicioJornada
      });
    } catch (Error) {
      console.error(Error);
      this.props.history.push("/login");
    }
  }
  _loginUser = appState => {
    
    if (appState) {
      //alert("volvi");
      if (appState.auth_token) {
        // console.log("----dentro del _loginUser: ", appState)
        this.setLocalStorage(appState);
      }
    } else {
      alert("Hubo un error al intentar Ingresar");
    }
  };

  _registerUser = (name, email, password, password_confirmation) => {
    var url = registrarUsuario;
    var data = {
      name: name,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    };

    fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(res => res.json())
      .then(response => {
        //console.log('Respuesta:', response)
        if (response.errors) {
          //console.log('error')
          alert(response.message, response.errors);
        } else {
          this.props.history.push("/login");
        }
      })
      .catch(error => {
        console.error("Error:", error);
        alert(`Error en el Registro!`);
        this.props.history.push("/register");
      });
  };

  _logoutUser = token => {
    //console.log(token)
    fetch(logout, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + this.state.auth_token
      },
      cache: "no-cache"
    })
      .then(res => res.json())
      .then(response => {
        console.log('Respuesta:', response)
        if (response.message === 'Unauthenticated.') {
          localStorage.clear();
          
        } else {
          let session = {};
          //this.props.addSession(session);
          localStorage.clear();
          let appState = {
            isLoggedIn: false,
            user_email: "",
            user_id: "",
            rol_id: "",
            auth_token: {}
          };

        }
      })
      .catch(error => {
        //console.log('--Error: ' + error);
      });
  };

  async componentDidMount() {
    debugger;
    
    const params = new URLSearchParams(this.props.location.search);
    //const token = window.localStorage.getItem("auth_token");
    //const empleado_id = window.localStorage.getItem("empleado_id");
    //const user_email = window.localStorage.getItem("user_email");
    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
    this.setState({loading : false});
  }
  async tick(){

    let {empleado_id, auth_token} = this.props.session;
    let token = null;// sirve para probar la llamada y que de 401
    let todo = await post({empleado_id}, getEmpleado, auth_token);
    this.setState({ loading: true }); 
    let donot = true;
    
    if( todo.message === "Unauthenticated."){
      debugger;
      localStorage.clear();
      this.props.session.isLoggedIn = false;
      this.props.session.auth_token = null;
      this.props.history.push("/signin");
      this.props.location.pathname = "/gfys";
    }else{
      this.props.session.employee = todo.data;
    }
  }

  setLayoutType = () => {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  };

  setNavStyle = () => {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  };

  render() {
    
    const { match, location, themeType, locale , session} = this.props;
    debugger;
    //if(!session.infoDash) this.tick();
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    }
    let refresh = false;
    
    if(location.pathname === "/logout"){
      localStorage.clear();
      this.props.session.auth_token = null;
      this.props.session.isLoggedIn = false;
      this.state.isLoggedIn = false;
      
      refresh = false;
    }
    let user_id = window.localStorage.getItem("user_id");
    let isLoggedIn = window.localStorage.getItem("isLoggedIn");
    let user_email = window.localStorage.getItem("user_email");
    let auth_token = window.localStorage.getItem("auth_token");
    let empleado_id = window.localStorage.getItem("empleado_id");
    let empleado_primerApellido = window.localStorage.getItem(
      "empleado_primerApellido"
    );
    let empleado_nombre = window.localStorage.getItem("empleado_nombre");

    let rol = window.localStorage.getItem("rol");
    let duracionJornada = window.localStorage.getItem("duracionJornada");
    let inicioJornada = window.localStorage.getItem("inicioJornada");

    
    // let token = window.localStorage.getItem('auth_token');
    if (
      isLoggedIn &&
      this.props.session.isLoggedIn !== "" &&
      location.pathname !== "/logout"
    ) {
      this.props.session.isLoggedIn = true;
      this.props.session.auth_token = auth_token;
      this.props.session.empleado_id = empleado_id;
      this.props.session.rol = rol;
      this.props.session.user_email = user_email;
      this.props.session.user_id = user_id;
      this.state.isLoggedIn = true;
      this.props.session.user_name = empleado_nombre;
      this.props.session.empleado_primerApellido = empleado_primerApellido;
      this.props.session.empleado_nombre = empleado_nombre;
      this.props.session.duracionJornada = duracionJornada;
      this.props.session.inicioJornada = inicioJornada;
      refresh = true;
    }
    
    if (
      !this.props.session.isLoggedIn &&
      location.pathname !== "/signin" &&
      location.pathname !== "/recuperar-pass" &&
      location.pathname !== "/logout"
    ) {
      //console.log( "Estas intentando entrar a una pagina sin estar logueado. Seras redicreccionado al Login" );
      this.props.history.push("/signin");
    }

    if (this.props.session.isLoggedIn && location.pathname === "/signin") {
      if (this.props.session.auth_token === "" && auth_token === "") {
        console.log("Unauthorized.");
        this.props.history.push("/signin");
      } else {
        console.log("Login Exitoso. Bienvenido a IOON!");
        this.props.history.push("/fichaje");
      }
    }

    if (location.pathname === "/" && !refresh) {
      return <Redirect to={"/fichaje"} />;
    }
    if(location.pathname === "/" && this.props.session.auth_token){
      return <Redirect to={"/fichaje"} />;
    }else if (location.pathname === "/" && !this.props.session.auth_token){
      return <Redirect to={"/signin"} />;
    }
    
    
    this.setLayoutType();

    this.setNavStyle();

    const currentAppLocale = AppLocale[locale.locale];
    return (
      
      <ConfigProvider locale={currentAppLocale.antd}>
        
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >{

          <Switch>
            <Route
              path={"/logout"}
              render={props => <Logout {...props} logout={this._logoutUser} />}
            />
            <Route path={"/recuperar-pass"} component={RecuperarPass} />
            {this.state.isLoggedIn ? (
              <Route
                pathName={this.props.location.pathname}
                path={`${match.url}`}
                isLoggedIn={this.state.isLoggedIn}
                component={MainApp}
              />
            ) : (
              <Route
                path={"/signin"}
                render={props => (
                  <SignInProvisorio {...props} loginUser={this._loginUser} />
                )}
              />
            )}
          </Switch>
        }
          
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  return { locale, navStyle, themeType, layoutType, session: settings.session };
};
export default connect(mapStateToProps, {
  addSession,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange
})(App);
