import React, { Component } from "react";
import { Avatar, Popover } from "antd";
import {  Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";

import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    session: state.settings.session,
    foto: state.settings.foto
  };
};

class UserInfo extends Component {
  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>Hola {this.props.session.user_name}!</li>
        <li>
          <Link to="/empleado/perfil">Perfil</Link>
        </li>
        
        <li>
          <Link to="/logout">Cerrar Sesión</Link>
        </li>
      </ul>
    );

    return (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        {
          <Avatar className="gx-avatar gx-pointer" size="large">
            {this.props.session.empleado_nombre.toUpperCase().slice(0, 1)}
            {this.props.session.empleado_primerApellido
              .toUpperCase()
              .slice(0, 1)}
          </Avatar>
        }
      </Popover>
    );
  }
}

export default connect(mapStateToProps, {})(UserInfo);
