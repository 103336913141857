import React, {Component} from "react";
import Auxiliary from "util/Auxiliary";

import ProfileHeader from "./ProfileHeader";
import { getEmpleado } from "routes/api/urls"
import { post } from "routes/api/Llamadas"
import Seccion from "./Routes/index";

import { connect } from "react-redux";
const mapStateToProps = state => {
    return {
        session: state.settings.session,
    }
}

class LayoutSeccionesPortal extends Component {
    constructor(props){
        super(props)
        this.state={
          empleado: null,
          empleado_id: this.props.session.empleado_id ? this.props.session.empleado_id : "",
        }
      }

    async componentDidMount(){
      debugger;
      try{
      const {  empleado_id, inicioJornada } = this.props.session;
      let token = this.props.session.auth_token
      let resp = await post({empleado_id}, getEmpleado, token)
      let emp = {}
      if(resp.success){
          emp= resp.data
      }

      this.setState({
        empleado: emp,
        inicioJornada: inicioJornada
      })

      } catch(Error) { console.error(Error);  }
    }


  render() {
    const {match} = this.props;
    const {history} = this.props;
     if(!this.state.empleado) return null
    return (
      
      <Auxiliary>
          <ProfileHeader usuario={this.state.empleado}/>
          
          <div className="gx-profile-content">
            <Seccion session={this.props.session} match={match} empleado={this.state.empleado} history={history}/>
          </div>
      </Auxiliary>
    )
  }
}

export default connect(mapStateToProps)(LayoutSeccionesPortal);
