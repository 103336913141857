import React, {Component} from "react";
import {Layout, Spin} from "antd";
import { post } from "routes/api/Llamadas";
import { selectInfoDash } from "routes/api/urls";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import {footerText} from "util/config";
import App from "routes/index";
import { connect } from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";

import CircularProgress from "components/CircularProgress";

const {Content, Footer} = Layout;

export class MainApp extends Component {
  constructor(props) {
    super(props)
    this.state={
        loader: true,
        loading: true
    }

  }
  async componentDidMount(){
   //this.tick();
    setTimeout(() => {
    this.setState({loader: false});
    }, 500);
  }
componentDidUpdate(){
  
}
  render() {
    //debugger;
    const { locale, width, navCollapsed, navStyle, match } = this.props;
    const {loader} = this.state;
    return (
      loader ? <CircularProgress className="gx-loader-400"/> :
      <Layout className="gx-app-layout">
          <Sidebar/>
        <Layout>
          <Topbar/>
          <Content className={`gx-layout-content `}>
            <App match={match}/>
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({settings}) => {
  const {width, navStyle} = settings;
  return {width, navStyle,
    session: settings.session,
  }
};
export default connect(mapStateToProps, {//getVacacionesData,
})(MainApp);