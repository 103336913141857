import React from "react";
import {Route, Switch} from "react-router-dom";
import FichajeLog from 'routes/RRHH/Fichaje/FichajeLog';
import Perfil from 'routes/Empleado/Fichaje/Perfil'

const Seccion = ({match, empleado, history, session}) =>{
debugger;
        let componente  = null
        switch(match.url){
                case "/empleado/perfil":
                        componente = <Route 
                                        path={`${match.url}`} 
                                        render={(props) => <Perfil session={session} empleado={empleado} history={history}/>}
                                />  
                        break;
        // case "/empleado/portal/gastos":
        //         componente = <Route 
        //                         path={`${match.url}`} 
        //                         render={(props) => <SeccionGastos empleado={empleado} history={history}/>}
        //                     /> 
        //         break;
    }
    return (
                <div>
                <Switch>
                        {componente}
                </Switch> 
                </div>
        );
}

export default Seccion;