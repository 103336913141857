import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import DayPicker from 'react-day-picker';
import classNames from 'classnames';

import {
  Col, Row, Alert,
  Button, Spin,
  Select, Input, Form,
  Card, Modal, Table, Icon, Checkbox
} from "antd";
import { Excel } from "antd-table-saveas-excel";
import {CSVLink} from "react-csv"

import {
  reportFichajeDia,
  reportFichajeAlMes,
  reportImputacion
} from "routes/api/urls";
import { post, get, put } from "routes/api/Llamadas";
import moment from "moment";

const { Option } = Select;

const mapStateToProps = state => ({
  session: state.settings.session
});

const WEEKDAYS_SHORT = {
  es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
};
const MONTHS = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
};

const dashColumns = [
  {
    title: "Identificación",
    dataIndex: "Identificacion",
    key: "Identificacion"
  },
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Provincia",
    dataIndex: "Provincia",
    key: "Provincia"
  },
  {
    title: "Cliente",
    dataIndex: "Cliente",
    key: "Cliente"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Email",
    dataIndex: "eMail",
    key: "eMail"
  },
  {
    title: "Fecha",
    dataIndex: "Fecha",
    key: "Fecha"
  },
  {
    title: "Hora Inicio",
    dataIndex: "Hora_inicio",
    key: "Hora_inicio"
  },
  {
    title: "Hora Fin",
    dataIndex: "Hora_fin",
    key: "Hora_fin"
  },
  {
    title: "Total horas",
    dataIndex: "total_horas",
    key: "total_horas"
  }
];

const dashColumnsMes = [
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Calendario",
    dataIndex: "Calendario",
    key: "Calendario"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
  {
    title: "Fecha",
    dataIndex: "F_Calendario",
    key: "F_Calendario"
  },
  {
    title: "Festivo",
    dataIndex: "Festivo",
    key: "Festivo"
  },
  {
    title: "Hora de Inicio",
    dataIndex: "Hora_inicio",
    key: "Hora_inicio"
  },
  {
    title: "Hora de Fin",
    dataIndex: "Hora_fin",
    key: "Hora_fin"
  },
  {
    title: "Total de Horas",
    dataIndex: "total_horas",
    key: "total_horas"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Identificación",
    dataIndex: "Identificacion",
    key:'IdentiMes'
  },
  {
    title: "Email",
    dataIndex: "eMail",
    key: "eMail"
  }

];

const dashColumnsImp = [
  {
    title: "Staff",
    dataIndex: "Staff_1",
    key: "Staff_1"
  },
  {
    title: "Nombre Empleado",
    dataIndex: "Nombre_Empleado",
    key: "Nombre_Empleado"
  },
  {
    title: "Fecha",
    dataIndex: "Fecha",
    key: "Fecha"
  },
  {
    title: "Calendario",
    dataIndex: "Calendario",
    key: "Calendario"
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status"
  },
  {
    title: "Festivo",
    dataIndex: "Festivo",
    key: "Festivo"
  },
  {
    title: "Cliente",
    dataIndex: "Cliente",
    key: "Cliente"
  },
  {
    title: "Proyecto/Cliente Final",
    dataIndex: "Proyecto_Cliente_final",
    key: "Proyecto_Cliente_final"
  },
  {
    title: "Tiempo Imputado Proyecto",
    dataIndex: "T_Imputado_Proyecto",
    key: "T_Imputado_Proyecto"
  },
  {
    title: "Tipo de Horas",
    dataIndex: "Tipo_horas",
    key: "Tipo_horas"
  },
  {
    title: "Tiempo Total Fichaje",
    dataIndex: "T_Total_Fichaje",
    key: "T_Total_Fichaje"
  },
  {
    title: "Tarea",
    dataIndex: "tarea",
    key: "tarea"
  },
  {
    title: "Subtarea",
    dataIndex: "subtarea",
    key: "subtarea"
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones"
  },
  {
    title: "Identificacion",
    dataIndex: "Identificacion",
    key: "Identificacion"
  },
  {
    title: "Email Corporativo",
    dataIndex: "emailCorp",
    key: "emailCorp"
  },
  {
    title: "Telefono",
    dataIndex: "telefono",
    key: "telefono"
  },
  {
    title: "Fecha de Alta",
    dataIndex: "fechaAlta",
    key: "fechaAlta"
  },
  {
    title: "Fecha de Baja",
    dataIndex: "fechaBaja",
    key: "fechaBaja"
  },
  {
    title: "Tiempo Imputado Transformado",
    dataIndex: "T_Imputado_Transformado",
    key: "T_Imputado_Transformado"
  },
  {
    title: "Tiempo de Diferencia",
    dataIndex: "T_Imputado_Diferencia",
    key: "T_Imputado_Diferencia"
  },
];

class Reports extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      loading: true,
      dataSourceFichajeDia: [],
      dataSourceFichajeMes: [],
      dataSourceImputacion: [],
      visibleAlDia: true,
      visibleMes: false,
      visibleImputacion: false,
      pickDate : false,
      fechaCurrent: new Date(moment()),
      fechaCurrentShow: moment().format("YYYY-MM-DD"),
      fields : { month: ''},
      months : MONTHS,
      monthSelected: moment(new Date()).format("MM") -1,
      anhoSelected: moment(new Date()).format("Y"),
    };

  }

  async componentDidMount() {
    //debugger;
    this.setState({loading:true});//mascara para mostrar cargando
    
    await this.getFichajeDia();
    //await this.getFichajeMes();
    //await this.getFichajeImputacion();
  }

  getFichajeDia = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true });
    let resp = await get(reportFichajeDia, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      //console.log(resp.data);
      debugger;
      this.setState({
        dataSourceFichajeDia : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  getFichajeMes = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true, dataSourceFichajeMes: []});
    let date = { date : this.state.fechaCurrentShow}
    let resp = await post(date, reportFichajeAlMes, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      console.log(resp.data);
      debugger;
      this.setState({
        dataSourceFichajeMes : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  getFichajeImputacion = async () => {
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true , dataSourceImputacion: []});
    let date = { date : this.state.fechaCurrentShow}
    let resp = await post(date, reportImputacion, auth_token);
    //console.log(resp.data.length);
    //console.log(resp);
    debugger;
    
    if(resp.data.length > 0){
      //console.log(resp.data);
      debugger;
      this.setState({
        dataSourceImputacion : resp.data
      });
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
    }
    
  }

  verListadoAlDia = async () => {
    this.setState({
      visibleAlDia: true,
      visibleMes: false,
      visibleImputacion: false
    });
  }

  verListadoAlMes = async () => {
    this.setState({
      visibleAlDia: false,
      visibleMes: true,
      visibleImputacion: false
    });
    await this.getFichajeMes();
  }

  verListadoImputacion = async () => {
    this.setState({
      visibleAlDia: false,
      visibleMes: false,
      visibleImputacion: true
    });
    await this.getFichajeImputacion();
  }

  handleClickDia = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(dashColumns)
      .addDataSource(this.state.dataSourceFichajeDia, {
        str2Percent: true
      })
      .saveAs("FichajeAlDia.xlsx");
  };

  handleClickMes = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(dashColumnsMes)
      .addDataSource(this.state.dataSourceFichajeMes, {
        str2Percent: true
      })
      .saveAs("FichajeMes.xlsx");
  };

  handleClickImputacion = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(dashColumnsImp)
      .addDataSource(this.state.dataSourceImputacion, {
        str2Percent: true
      })
      .saveAs("Imputaciones.xlsx");
  };

  pickADate = async () => {
    //Levanta modal seleccion de fecha
    this.setState({pickDate : true});
  }
  closeDatePicker = async () => {
    //Cierra modal seleccion de fecha
    this.setState({pickDate : false});
  }
  manipulateDates = async (date) => {
    //Aquí haremos toda la lógica para consultar la fecha  que se seleccione
    this.setState({
      fechaCurrentShow: moment(date).format("Y-MM-DD"), 
      pickDate : false,
      anhoSelected: moment(date).format("Y")
    });
    
  }

  handleDayClick  = async  (day, { selected, disabled }) => { //, { selected }

    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    let monthActual = moment().month(day).format('MM');
    let anoActual = this.state.anhoSelected;
    let fechaCompleta = anoActual+'-'+monthActual+"-01";
    let {visibleImputacion, visibleMes} = this.state;

    this.setState({
      fechaCurrent: day, //selected ? undefined :
      monthSelected: day,
      anhoSelected: anoActual,
      fechaCurrentShow: moment(fechaCompleta).format('YYYY-MM-DD')
    });
    //await this.manipulateDates(moment(fechaCompleta).format('YYYY-MM-DD'));
    //visibleMes ? await this.handleOk() : await this.handleOkImputacion(); ;
    
    console.log(day, this.state.fechaCurrent);
  }
  handleYearClick  = async  (year) => { //, { selected }

    let MesSelected = this.state.monthSelected;
    let monthActual = moment().month(MesSelected).format('MM');
    let anoActual = year;
    let fechaCompleta = anoActual+'-'+monthActual+"-01";

    this.setState({
      monthSelected: MesSelected,
      anhoSelected: anoActual,
      fechaCurrentShow: moment(fechaCompleta).format('YYYY-MM-DD')
    });
    //await this.manipulateDates(moment(fechaCompleta).format('YYYY-MM-DD'));
    //await this.handleOk();
    console.log(year, this.state.fechaCurrent);
  }

  handleChangeInput = (event) => {
   
    console.log(this.state.fechaCurrent);
    console.log(this.state.fechaCurrentShow);

    let name = event.target.name;

    console.log(name);
    console.log(event.target.value);
  }

  handleOk = async () => {

    this.setState({ loading: true });
    await this.getFichajeMes()

  };

  handleOkImputacion = async () => {

    this.setState({ loading: true });
    this.getFichajeImputacion()

  };
  
  render() { 
    let now = new Date();
    let MesSelected = moment(this.state.fechaCurrentShow).format('MM') -1 ;
    let b_month = moment(now).format("MM") -1
    let year = now.getFullYear();
    let b_day = moment(now).format("DD")
    const {loading} = this.state;

    const disabledDays =  [
      { daysOfWeek: [0,6]},
    ]

    let months = this.state.months.es;
 
    let mesesDrop = months.map(function(val, index){
      return {key:index, value:val};
    });
    let opciones = mesesDrop.map( x => <Option key={x.key} value={x.key} required={true}>{x.value} </Option>)
    let anhos = [year, year -1]
    let anhosDrop = anhos.map(function(val, index){
      return {key:index, value:val};
    });

    let opcionesAnho = anhosDrop.map( x => <Option key={x.value} value={x.value} required={true}>{x.value} </Option>)

    return (
      <div>
        <Spin spinning={loading}>
        <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
              <Card title="Informes de Fichaje" className="gx-card" extra={
            <span>  
              <div>{[ 
                <Button key="btn_dia" type="default"  onClick={() => this.verListadoAlDia()} className={classNames({active: this.state.visibleAlDia})}>
                  Fichaje del Dia
                </Button>,
                <Button key="btn_mes" type="default" onClick={() => this.verListadoAlMes()} className={classNames({active: this.state.visibleMes})}>
                  Fichaje del Mes
                </Button>,
                <Button key="btn_imputacion" type="default" onClick={() => this.verListadoImputacion()} className={classNames({active: this.state.visibleImputacion})}>
                  Imputaciones
                </Button>
                ]}
              </div>
              </span>}> {/**/}
                <div>
                  {this.state.visibleAlDia === true ?
                    <div>
                      <div style={{marginBottom: "15px"}}>
                        <span>
                          Fichaje del Día
                        </span>
                      </div>
                      <span style={{marginBottom: "15px"}}>
                        <Button block type="primary" onClick={this.handleClickDia} style={{marginBottom: "0px", marginRight: "0px", width: "160px", marginBottom:"20px"}}>
                          Exportar Excel
                        </Button>
                      </span>
                      <Table
                        rowKey="Identificacion"
                        size="small"
                        dataSource={this.state.dataSourceFichajeDia}
                        columns={dashColumns}
                        pagination={true} 
                      />
                    </div>
                    : null}
                    {this.state.visibleMes === true ?
                    <div>
                      <div style={{marginBottom: "15px"}}>
                        <span>
                          Fichaje del Mes
                        </span>
                        <div style={{marginBottom: "15px"}}>
                        <Form layout="inline">
                        <Form.Item label="Seleccione Año">
                            <Select value={this.state.anhoSelected} style={{ color: "black", minWidth: "30vh"  }} onChange={this.handleYearClick}>
                              {opcionesAnho}
                            </Select>
                          </Form.Item>
                          <Form.Item label="Seleccione Mes">
                            <Select value={this.state.monthSelected} style={{ color: "black", minWidth: "30vh" }} onChange={this.handleDayClick}>
                              {opciones}
                            </Select>
                          </Form.Item>
                          <Form.Item >
                          <Button
                            name="submit"
                            value="Buscar"
                            type="primary"
                            required={true}
                            onClick={this.handleOk}
                            style={{width: "120px", marginLeft: 30}}
                          >
                            Buscar
                          </Button>
                        </Form.Item>
                        </Form>
                      </div>
                      </div>
                      <span>
                        <Button block type="primary" onClick={this.handleClickMes} style={{marginBottom: "0px", marginRight: "0px", width: "160px", marginBottom:"20px"}}>
                          Exportar Excel
                        </Button>
                      </span>
                      <Table
                        rowKey="rowid"
                        size="small"
                        dataSource={this.state.dataSourceFichajeMes}
                        columns={dashColumnsMes}
                        pagination={true} 
                      />
                    </div>
                    : null}
                    {this.state.visibleImputacion === true ?
                    <div>
                      <div style={{marginBottom: "15px"}}>
                        <span>
                          Imputaciones
                        </span>
                        <div style={{marginBottom: "15px"}}>
                        <Form layout="inline">
                        <Form.Item label="Seleccione Año">
                            <Select value={this.state.anhoSelected} style={{ color: "black", minWidth: "30vh"  }} onChange={this.handleYearClick}>
                              {opcionesAnho}
                            </Select>
                          </Form.Item>
                          <Form.Item label="Seleccione Mes">
                            <Select value={this.state.monthSelected} style={{ color: "black", minWidth: "30vh" }} onChange={this.handleDayClick}>
                              {opciones}
                            </Select>
                          </Form.Item>
                          <Form.Item >
                          <Button
                            name="submit"
                            value="Buscar"
                            type="primary"
                            required={true}
                            onClick={this.handleOkImputacion}
                            style={{width: "120px", marginLeft: 30}}
                          >
                            Buscar
                          </Button>
                        </Form.Item>
                        </Form>
                        {/* <Form layout="vertical">
                          
                          {[
                          <Form.Item label="Seleccione Mes">
                          <Input
                            name="month"
                            type="text"
                            className="center-block"
                            value={this.state.fechaCurrentShow}
                            maxLength={100}
                            required={true}
                            onChange={this.handleChangeInput}
                            onClick={this.pickADate}
                            style={{width: "110px"}}
                          />
                          <Input
                            name="submit"
                            type="button"
                            value="Buscar"
                            className="primary"
                            required={true}
                            onClick={this.handleOkImputacion}
                            style={{width: "120px", marginLeft: 30}}
                          />
                        </Form.Item>
                        ]}
                        </Form> */}
                      </div>
                      </div>
                      <span>
                        <Button block type="primary" onClick={this.handleClickImputacion} style={{marginBottom: "0px", marginRight: "0px", width: "160px", marginBottom:"20px"}}>
                          Exportar Excel
                        </Button>
                      </span>
                      <Table
                        rowKey="rowid"
                        size="small"
                        dataSource={this.state.dataSourceImputacion}
                        columns={dashColumnsImp}
                        pagination={true} 
                        scroll={{ x: 2500}}
                      />
                    </div>
                    : null}
                </div>
              </Card>
            </Col>
            </Row>
        </Spin>
        <Modal
          title="Seleccione Fecha"
          visible={this.state.pickDate}
          onCancel={this.closeDatePicker}
          width={350}
          footer={[
            <Button key="Finalizar" type="primary"
                  onClick={this.manipulateDates}
                   >
              Seleccionar
            </Button>
          ]}
        >
        <div>
          <DayPicker
            mode="single"
            selectedDays={this.state.fechaCurrent}
            useWeekdaysShort={true}
            weekdaysShort={WEEKDAYS_SHORT['es']}
            months={MONTHS['es']}
            onDayClick={this.handleDayClick}
            disabledDays={disabledDays}
          />
        </div>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(Reports);