import React from "react";
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import logo from '../assets/logos/logo_ioon_2020.png';


class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          token: this.props.session.auth_token
            ? this.props.session.auth_token
            : "",
        };
      }
componentDidMount(){
    if(this.state.token !== ""){
            this.props.logout(this.state.token)
    }
}
    render(){
      return(
          <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-login-content" style={{ margin: "auto" }}>
                <div style={{ textAlign: "center" }} className="gx-app-logo">
                  <img style={{ width: "40%" }} src={logo} alt='Logo'/>
                </div>
                <br/>
                <br/>
                <p className="gx-text-center"><Link to="/signin">Volver a Entrar</Link></p>
              </div>
            </div>
          </div>
        </div>
      )
    }
}
const mapStateToProps = ({settings}) => {
  return { session: settings.session, }
};
export default connect(mapStateToProps, {})(Logout);

