import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
//import { Icon } from 'antd';
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state={
          rol: this.props.session.rol,
          user_id: this.props.session.user_id 
            ? this.props.session.user_id 
            : "",
    }
 }

 async componentDidMount(){
  let user_id = this.props.session.user_id 
  ? this.props.session.user_id 
  : ""
  //console.log(user_id, "---------------------")
  try{
    let data = {
      id: user_id
    }
    let token = this.props.session.auth_token
    // let rol = await post(data, selectRoleUser, token)  
    // //console.log(rol)  
    // this.setState({
    //   rol: rol,
    // })
    //implementar roles? 
  } catch(Error) {
      console.error(Error);
  }

}

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    //console.log("rol", this.state.rol)
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content" style={{ backgroundColor: "rgba(209, 209, 208, 1)"}}>
          <br/>         
          <br/>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {/* Si es perfil administrador */}
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
                <Menu.Item key="dashboardEmpleado">
                  <Link to="/fichaje"><i className="icon icon-check-circle-o"/>
                    Fichaje
                  </Link>
                </Menu.Item>
                <Menu.Item key="portalEmpleado">
                  <Link to={{pathname : "https://capitalhumano.ioon.es/"}}  target="_blank" ><i className="icon icon-check-circle-o"/>
                      Portal del Empleado
                  </Link>
                </Menu.Item>
                <Menu.Item key="ayuda">
                  <Link to="/files/manual.pdf"  target="_blank" download><i className="icon icon-check-circle-o"/>
                    Ayuda
                  </Link>
                </Menu.Item>
                {/*<Menu.Item key="roles" style={{display: (this.state.rol == 2)? "block":"none"}}>
                  <Link to="/mantenimiento/roles"><i className="icon icon-check-circle-o"/>
                    Roles
                  </Link>
                </Menu.Item>*/}
                <Menu.Item key="empleados"  style={{display: (this.state.rol == 2)? "block":"none"}}>
                  <Link to="/mantenimiento/employees"><i className="icon icon-check-circle-o"/>
                    Empleados
                  </Link>
                </Menu.Item>

                 <Menu.Item key="clientes"  style={{display: (this.state.rol == 2)? "block":"none"}}>
                  <Link to="/mantenimiento/clients"><i className="icon icon-check-circle-o"/>
                    Clientes
                  </Link>
                </Menu.Item>
                <Menu.Item key="informes"  style={{display: (this.state.rol == 2)? "block":"none"}}>
                  <Link to="/mantenimiento/reports"><i className="icon icon-check-circle-o"/>
                    Informes
                  </Link>
                </Menu.Item>
                <Menu.Item key="upload-finanzas"  style={{display: (this.state.rol == 2)? "block":"none"}}>
                  <Link to="/mantenimiento/upload/finanzas"><i className="icon icon-check-circle-o"/>
                    Subir Excel Finanzas
                  </Link>
                </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {
    navStyle, 
    themeType, 
    locale, 
    pathname,
    session: settings.session,
  }
};
export default connect(mapStateToProps)(SidebarContent);