import React from "react";
import {Route, Switch} from "react-router-dom";

import LayoutSeccionesPortal from "./Empleado/Portal/LayoutSeccionesPortal"
import EmpleadoDash from     './Fichaje/index';
import EmpleadoFichaje from './Empleado/Fichaje/index'
import EmpleadoAdmin from './Empleado/Admin/index'
import FichajeDaily from './Fichaje/daily'
import Roles from './Empleado/Admin/Roles/index'
import Empleados from './Empleado/Admin/Empleado/index'
import Informes from './Empleado/Admin/Informes/index'
import UploadFinanzas from './Empleado/Admin/Informes/upload-excel-finanzas'
import Clients from "./Empleado/Admin/Clientes/index";


//import Tareas from "./Extras/Tareas";
//import Mensajes from "./Extras/Mensajes";
//import Notificaciones from "./Extras/Notificaciones";

// import AdminDash from "./Administracion/Dashboard/index";
// import ReservasCoche from "./Administracion/ReservasCoche/index"
// import Inventario from "./Administracion/Inventario/index"
// import InventarioForm from "./Administracion/Inventario/form"
// import TipoForm from "./Administracion/Inventario/tipoForm";
// import Servidores from "./Administracion/Servidores/index";
// import ServidoresCorreo from "./Administracion/ServidoresCorreo/index";

// import MOTD from "./Administracion/MOTD/index";
// import MOTDform from "./Administracion/MOTD/form";

// import Bancos from "./Administracion/Bancos/ver"
// import BancosForm from "./Administracion/Bancos/form"
// import Tarjetas from "./Administracion/Bancos/Tarjetas/index"
// import TarjetasForm from "./Administracion/Bancos/Tarjetas/form"


// import RRHHAusenciasForm from './RRHH/Ausencias/form'
// import RRHHAusencias from './RRHH/Ausencias/index'
// import RRHHGastosForm from './RRHH/Gastos/form'
// import RRHHGastos from './RRHH/Gastos/index'

// import RecuentoHorasForm from "./RRHH/Fichaje/RecuentoHoras/form"
// import RRHHFichaje from "./RRHH/Fichaje/index"
// import RRHHCompensacionesForm from "./RRHH/Compensaciones/form"
// import RRHHCompensaciones from "./RRHH/Compensaciones/index"

// import RRHHBajas from "./RRHH/Bajas/index"
// import RRHHBajasForm from "./RRHH/Bajas/form"
// import RRHHVacaciones from "./RRHH/Vacaciones/index"
// import RRHHVacacionesForm from "./RRHH/Vacaciones/form"
// import RRHHVacacionesFormTodos from "./RRHH/Vacaciones/formTodos"
// import RRHHVacacionesSolicitudForm from "./RRHH/Vacaciones/formSolicitud"

// import RRHHDash from "./RRHH/Dashboard/index"
// import LayoutSeccionesEmpleado from "./RRHH/Empleados/LayoutSeccionesEmpleado"
// import BajaEmpleadosForm from "./RRHH/Empleados/BajaEmpleadoForm"
// import EmpleadosForm from "./RRHH/Empleados/form"
// import Empleados from "./RRHH/Empleados/ver"
// import CalendarioRRHH from "./RRHH/Calendarios/index"
// import CalendarioRRHHForm from "./RRHH/Calendarios/form"
// import CalendarioGestionar from "./RRHH/Calendarios/gestionar"


// import RecruitingDash from "./Recruiting/Dashboard/index"
// import Leads from "./CRM/Leads/index"
// import LeadForm from "./CRM/Leads/form"
// import CRMDash from "./CRM/Dashboard/index"
// import GruposForm from "./CRM/Grupos/form"
// import Grupos from "./CRM/Grupos/index"

// import LayoutSeccionesCliente from './CRM/Clientes/LayoutSeccionesCliente'
// import ClientesForm from "./CRM/Clientes/form"
// import Clientes from "./CRM/Clientes/index"
// import ParamsPorClientesForm from "./CRM/Clientes/ParamsPorClientesForm"
// import ContactosVer from "./CRM/Contactos/ver"
// import Contactos from "./CRM/Contactos/index"
// import ContactosForm from "./CRM/Contactos/form"


// import DocumentosClientesForm from './CRM/Documentos/form'
// import DocumentosClientes from './CRM/Documentos/ver'
// import ProyectosForm from './CRM/Proyectos/form'
// import ProyectosDetalle from './CRM/Proyectos/detalle'
// import Proyectos from './CRM/Proyectos/ver'
// import OportunidadesDash from "./CRM/Oportunidades/dashOportunidades"

// import Oportunidades    from "./CRM/Oportunidades/ver"
// import OportunidadesVer from "./CRM/Oportunidades/ver"
// import OportunidadesDetalle from './CRM/Oportunidades/OportunidadesDetalle'
// import OportunidadesForm from "./CRM/Oportunidades/formOportunidad"
// import PerfilForm from "./CRM/Oportunidades/formPerfil"

// import RecursoForm from "./CRM/Oportunidades/Recursos/form"
// import asignarrecruiter from "./CRM/Oportunidades/Recursos/asignarrecruiter"
// import asignarJefeProyecto from "./CRM/Oportunidades/Recursos/asignarjefeproyecto"
// import PerfilGestionar from "./CRM/Oportunidades/gestionarPerfiles"

// import FacturacionDash from "./Facturacion/Dashboard/index"
// import ModificarFacturasForm from "./Facturacion/Facturas/ModificarFacturasForm"
// import VerDetalleFacturas from "./Facturacion/Facturas/VerDetalleFacturas"

// import FacturasForm from "./Facturacion/Facturas/formFactura"
// import AbonoForm from "./Facturacion/Facturas/formAbono"

// import Facturas from "./Facturacion/Facturas/index"
// import PedidosVerDetalle from './Facturacion/Pedidos/verDetalle'
// import PedidosForm from "./Facturacion/Pedidos/form"
// import Pedidos from "./Facturacion/Pedidos/ver"
// import FacturacionForm from "./Facturacion/Pedidos/formFacturacion"
// import DetalleForm from "./Facturacion/Pedidos/formDetalle"
// import FormParametros from "./Facturacion/Pedidos/formParametros"

// import FormParametroFactura from "./Facturacion/Facturas/FormParametroFactura"
// import FormParametroIndividual from "./Facturacion/Pedidos/formParametroPorPedidoIndividual"
// import FormDetalle from "./Facturacion/Pedidos/formDetalle"
// import FormAlbaranes from "./Facturacion/Pedidos/formAlbaranes"

// import Tecnologias from "./Mantenimiento/Tecnologias/index"
// import TecnologiasForm from "./Mantenimiento/Tecnologias/form"
// import Idiomas from "./Mantenimiento/Idiomas/index"
// import IdiomasForm from "./Mantenimiento/Idiomas/form"
// import PlanFlexible from "./Mantenimiento/PlanFlexible/index"
// import PlanFlexibleForm from "./Mantenimiento/PlanFlexible/form"
// import Roles from "./Mantenimiento/Roles/index"
// import RolesForm from "./Mantenimiento/Roles/form"
// import Permisos from "./Mantenimiento/Permisos/index"
// import PermisosForm from "./Mantenimiento/Permisos/form"
// import Horarios from "./Mantenimiento/Horarios/index"
// import HorariosForm from "./Mantenimiento/Horarios/form"
// import Proveedores from "./Mantenimiento/Proveedores/index"
// import ProveedoresForm from "./Mantenimiento/Proveedores/form"
// import Talonarios from "./Mantenimiento/Talonarios/index"
// import TalonariosForm from "./Mantenimiento/Talonarios/form"
// import Festivos from "./Mantenimiento/Festivos/index"
// import FestivosForm from "./Mantenimiento/Festivos/form"

// import Empresa from "./Mantenimiento/Empresa/form"

// import EmpleadoProyectoHorarioForm from "./RRHH/Empleados/EmpleadoProyectoHorarioForm"

// import PresupuestosDetalle from "./TheLab/Presupuestos/detalle"
// import PresupuestosForm from "./TheLab/Presupuestos/form"
// import Presupuestos from "./TheLab/Presupuestos/index"

// import GestionProyectos from "./TheLab/GestionProyectos/index"

// import CalendarioAnualRRHH from "./RRHH/CalendarioAnualRRHH";

// import Produccion from "./Produccion/index";
// import ProduccionComparacion from "./Produccion/comparacion";
// import ProduccionFacturacionForm from "./Produccion/formFacturacion";

// import PDF from "./PDF/index";
// import Noticias from "./CRM/Noticias";
// import editarNoticia from "./CRM/Noticias/form"
// import NoticiasForm from "./CRM/Noticias/new-form"
// import verNoticia  from "./CRM/Noticias/ver"

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
       {/* Modulo Empleado */}
      <Route path={`${match.url}fichaje`} component={EmpleadoDash}/>
      <Route path={`${match.url}empleado/fichaje`} component={EmpleadoFichaje}/>
      <Route path={`${match.url}empleado/admin`} component={EmpleadoAdmin}/>
      <Route path={`${match.url}empleado/perfil`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}daily`} component={FichajeDaily}/>


      
      
      {/* <Route path={`${match.url}empleado/portal/perfil`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/gastos`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/vacaciones`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/ausencias`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/bajas`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/compensaciones`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/documentos`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/calendario-anual`} component={LayoutSeccionesPortal}/>
      <Route path={`${match.url}empleado/portal/ajustes`} component={LayoutSeccionesPortal}/> */}


      {/* MODULO MANTENIMIENTO  */}
      
      <Route path={`${match.url}mantenimiento/roles`} component={Roles}/>
      <Route path={`${match.url}mantenimiento/employees`} component={Empleados}/>
      <Route path={`${match.url}mantenimiento/reports`} component={Informes}/>
      <Route path={`${match.url}mantenimiento/upload/finanzas`} component={UploadFinanzas}/>
      <Route path={`${match.url}mantenimiento/clients`} component={Clients}/>

      
      {/* 
      <Route path={`${match.url}mantenimiento/permisos/form`} component={PermisosForm}/>
      <Route path={`${match.url}mantenimiento/permisos`} component={Permisos}/>
      <Route path={`${match.url}mantenimiento/roles/form`} component={RolesForm}/>
      <Route path={`${match.url}mantenimiento/idiomas/form`} component={IdiomasForm}/>
      <Route path={`${match.url}mantenimiento/idiomas`} component={Idiomas}/>
      <Route path={`${match.url}mantenimiento/tecnologias/form`} component={TecnologiasForm}/>
      <Route path={`${match.url}mantenimiento/tecnologias`} component={Tecnologias}/>
      <Route path={`${match.url}mantenimiento/planflexible/form`} component={PlanFlexibleForm}/>
      <Route path={`${match.url}mantenimiento/planflexible`} component={PlanFlexible}/>
      <Route path={`${match.url}mantenimiento/horarios/form`} component={HorariosForm}/>
      <Route path={`${match.url}mantenimiento/horarios`} component={Horarios}/>
      <Route path={`${match.url}mantenimiento/proveedores/form`} component={ProveedoresForm}/>
      <Route path={`${match.url}mantenimiento/proveedores`} component={Proveedores}/>
      <Route path={`${match.url}mantenimiento/talonarios/form`} component={TalonariosForm}/>
      <Route path={`${match.url}mantenimiento/talonarios`} component={Talonarios}/>
      <Route path={`${match.url}mantenimiento/festivos/form`} component={FestivosForm}/>
      <Route path={`${match.url}mantenimiento/festivos`} component={Festivos}/>
      <Route path={`${match.url}mantenimiento/empresa`} component={Empresa}/> */}
      

      {/* <Route path={`${match.url}rrhh/calendarios/form`} component={CalendarioRRHHForm}/>
      <Route path={`${match.url}rrhh/calendarios/gestionar`} component={CalendarioGestionar}/>
      <Route path={`${match.url}rrhh/calendarios`} component={CalendarioRRHH}/> */}

      {/* Extras comunes a todos los modulos */}
      {/* <Route path={`${match.url}extras/tareas`} component={Tareas}/>
      <Route path={`${match.url}extras/mensajes`} component={Mensajes}/>
      <Route path={`${match.url}extras/notificaciones`} component={Notificaciones}/> */}

      {/* Modulo Administracion */}
      {/* <Route path={`${match.url}admin/dashboard`} component={AdminDash}/>
      <Route path={`${match.url}admin/reservas-coche`} component={ReservasCoche}/>
      <Route path={`${match.url}admin/inventario/tipoform`} component={TipoForm}/>
      <Route path={`${match.url}admin/inventario/form`} component={InventarioForm}/>
      <Route path={`${match.url}admin/inventario`} component={Inventario}/>
      <Route path={`${match.url}admin/motd/form`} component={MOTDform}/>
      <Route path={`${match.url}admin/motd`} component={MOTD}/>
      <Route path={`${match.url}admin/servidores`} component={Servidores}/>
      <Route path={`${match.url}admin/servidorescorreo`} component={ServidoresCorreo}/>
      <Route path={`${match.url}admin/pdf`} component={PDF}/> 


    {/*
      <Route path={`${match.url}admin/roles/form`} component={RolesForm}/>
      <Route path={`${match.url}admin/roles`} component={Roles}/>
      *
      <Route path={`${match.url}admin/bancos/form`} component={BancosForm}/>
      <Route path={`${match.url}admin/bancos`} component={Bancos}/>
      <Route path={`${match.url}admin/tarjetas/form`} component={TarjetasForm}/>
      <Route path={`${match.url}admin/tarjetas`} component={Tarjetas}/>/*}
      
      {/* MODULO FACTURACION */}
      {/* <Route path={`${match.url}facturacion/generarPDF`} />
      <Route path={`${match.url}facturacion/factura/formparametrofactura`} component={FormParametroFactura}/>
      <Route path={`${match.url}facturacion/dashboard`} component={FacturacionDash}/>
      <Route path={`${match.url}facturacion/facturas/ver`} component={VerDetalleFacturas}/>
      <Route path={`${match.url}facturacion/facturas/modificar`} component={ModificarFacturasForm}/>
      <Route path={`${match.url}facturacion/facturas/form`} component={FacturasForm}/>
      <Route path={`${match.url}facturacion/facturas/abonoform`} component={AbonoForm}/>
      <Route path={`${match.url}facturacion/facturas`} component={Facturas}/>

      <Route path={`${match.url}facturacion/pedidos/formparametroindividual`} component={FormParametroIndividual}/>
      <Route path={`${match.url}facturacion/pedidos/formparametros`} component={FormParametros}/>
      <Route path={`${match.url}facturacion/pedidos/form`} component={PedidosForm}/>
      <Route path={`${match.url}facturacion/pedidos/facturas/detalles/form`} component={FormDetalle}/>
      <Route path={`${match.url}facturacion/pedidos/facturas/albaranes/form`} component={FormAlbaranes}/>
      <Route path={`${match.url}facturacion/pedidos/facturas/form`} component={FacturacionForm}/>
      <Route path={`${match.url}facturacion/pedidos/detalles/form`} component={DetalleForm}/>
      <Route path={`${match.url}facturacion/pedidos/verdetalle`} component={PedidosVerDetalle}/>
      <Route path={`${match.url}facturacion/pedidos`} component={Pedidos}/>
      
      
      <Route path={`${match.url}produccion/comparacion`} component={ProduccionComparacion}/>
      <Route path={`${match.url}produccion/facturacion/form`} component={ProduccionFacturacionForm}/>
      <Route path={`${match.url}produccion`} component={Produccion}/> */}
      

        {/* Gestion de Empleados (RRHH) */}
      {/* <Route path={`${match.url}rrhh/empleado-proyecto-horario/form`} component={EmpleadoProyectoHorarioForm}/>

      <Route path={`${match.url}rrhh/empleados/secciones/detalle`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/reconocimientomedico`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/estudios`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/documentos`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/puestos`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/proyectos`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/planflexible`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/inventario`} component={LayoutSeccionesEmpleado}/>
{/*------nuevos--------- */}
      {/* <Route path={`${match.url}rrhh/empleados/secciones/gastos`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/compensaciones`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/ausencias`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/bajas`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/vacaciones`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/fichaje`} component={LayoutSeccionesEmpleado}/>
      <Route path={`${match.url}rrhh/empleados/secciones/calendario`} component={LayoutSeccionesEmpleado}/> */}



      {/* <Route path={`${match.url}rrhh/empleados/formbaja`} component={BajaEmpleadosForm}/>
      <Route path={`${match.url}rrhh/empleados/form`} component={EmpleadosForm}/>
      <Route path={`${match.url}rrhh/empleados`} component={Empleados}/>
      
      {/* Modulo RRHH */}
      {/* <Route path={`${match.url}rrhh/ausencias/form`} component={RRHHAusenciasForm}/>
      <Route path={`${match.url}rrhh/ausencias`} component={RRHHAusencias}/>
      <Route path={`${match.url}rrhh/gastos/form`} component={RRHHGastosForm}/>
      <Route path={`${match.url}rrhh/gastos`} component={RRHHGastos}/>

      <Route path={`${match.url}rrhh/compensaciones/form`} component={RRHHCompensacionesForm}/>
      <Route path={`${match.url}rrhh/compensaciones`} component={RRHHCompensaciones}/>
      <Route path={`${match.url}rrhh/bajas/form`} component={RRHHBajasForm}/>
      <Route path={`${match.url}rrhh/bajas`} component={RRHHBajas}/>
      <Route path={`${match.url}rrhh/vacaciones/formSolicitud`} component={RRHHVacacionesSolicitudForm}/>
      <Route path={`${match.url}rrhh/vacaciones/formTodos`} component={RRHHVacacionesFormTodos}/>
      <Route path={`${match.url}rrhh/vacaciones/form`} component={RRHHVacacionesForm}/>
      <Route path={`${match.url}rrhh/vacaciones`} component={RRHHVacaciones}/>
      <Route path={`${match.url}rrhh/fichaje`} component={RRHHFichaje}/>
      <Route path={`${match.url}rrhh/calendario-anual`} component={CalendarioAnualRRHH}/>
      <Route path={`${match.url}rrhh/recuentohoras/form`} component={RecuentoHorasForm}/>
      <Route path={`${match.url}rrhh/dashboard`} component={RRHHDash}/> */} 
      
      {/* Modulo Recruiting */}
      {/* <Route path={`${match.url}recruiting/dashboard`} component={RecruitingDash}/> */}
      {/* Modulo CRM */}
      {/* <Route path={`${match.url}crm/dashboard`} component={CRMDash}/>
      <Route path={`${match.url}crm/lead-form`} component={LeadForm}/>
      <Route path={`${match.url}crm/leads`} component={Leads}/>

      <Route path={`${match.url}crm/clientes/secciones/detalle`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/oportunidades`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/contactos`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/proyectos`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/empleados`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/pedidos`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/bancos`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/documentos`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/calendario`} component={LayoutSeccionesCliente}/>
      <Route path={`${match.url}crm/clientes/secciones/planfacturacion`} component={LayoutSeccionesCliente}/>

      <Route path={`${match.url}crm/clientes/parametros/form`} component={ParamsPorClientesForm}/>
      <Route path={`${match.url}crm/clientes/form`} component={ClientesForm}/>
      <Route path={`${match.url}crm/clientes`} component={Clientes}/>

      <Route path={`${match.url}crm/grupos/form`} component={GruposForm}/>
      <Route path={`${match.url}crm/grupos`} component={Grupos}/>

      <Route path={`${match.url}crm/contactos/ver`} component={ContactosVer}/>
      <Route path={`${match.url}crm/contactos/form`} component={ContactosForm}/>
      <Route path={`${match.url}crm/contactos`} component={Contactos}/>
   
      <Route path={`${match.url}crm/oportunidades/recurso/asignarrecruiter`} component={asignarrecruiter}/>
      <Route path={`${match.url}crm/oportunidades/recurso/asignarjefeproyecto`} component={asignarJefeProyecto}/>
      <Route path={`${match.url}crm/oportunidades/recurso/form`} component={RecursoForm}/>
      <Route path={`${match.url}crm/oportunidades/detalle`} component={OportunidadesDetalle}/>
      <Route path={`${match.url}crm/oportunidades/ver`} component={OportunidadesVer}/>
      <Route path={`${match.url}crm/oportunidades/perfil/gestionar`} component={PerfilGestionar}/>
      <Route path={`${match.url}crm/oportunidades/perfil/form`} component={PerfilForm}/>
      <Route path={`${match.url}crm/oportunidades/form`} component={OportunidadesForm}/>
      <Route path={`${match.url}crm/oportunidades`} component={OportunidadesDash}/>
      
      
      <Route path={`${match.url}crm/proyectos/detalle`} component={ProyectosDetalle}/>
      <Route path={`${match.url}crm/proyectos/form`} component={ProyectosForm}/>
      <Route path={`${match.url}crm/proyectos`} component={Proyectos}/>
      
      <Route path={`${match.url}crm/documentos/form`} component={DocumentosClientesForm}/>
      <Route path={`${match.url}crm/documentos`} component={DocumentosClientes}/>

       <Route path={`${match.url}crm/noticias/ver`} component={verNoticia}/>
      <Route path={`${match.url}crm/noticias/edit`} component={NoticiasForm} />
      <Route path={`${match.url}crm/noticias/form`} component={NoticiasForm}/>
      <Route path={`${match.url}crm/noticias`} component={Noticias}/> */}



{/*----------------- THE LAB ------------------- */}


      {/* <Route path={`${match.url}thelab/presupuestos/detalle`} component={PresupuestosDetalle}/>
      <Route path={`${match.url}thelab/presupuestos/form`} component={PresupuestosForm}/>
      <Route path={`${match.url}thelab/presupuestos`} component={Presupuestos}/>
      <Route path={`${match.url}thelab/gestion-proyectos`} component={GestionProyectos}/> */}
      
         </Switch>
  </div>
);

export default App;
