import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import {
  Col, Row,
  Button, Spin,
  Select, Input, Form,
  Card, Modal, Table, Icon
} from "antd";
import {
  getRoles,
  insertRoles
} from "routes/api/urls";
import { post, get } from "routes/api/Llamadas";
import FormItem from "antd/lib/form/FormItem";

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};
const formItemLayoutSelect = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

const mapStateToProps = state => ({
  session: state.settings.session
});

class ListRoles extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      dataSource: [],
      loading: true,
      nombre: '',
      visible: false,
      fields: { nombre: ''},
      errors: {},
      disabledSave: false
    };

  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Nombre
    if (!fields["nombre"]) {
      formIsValid = false;
      errors["nombre"] = "No puede ser vacío";
    }

    this.setState({ errors: errors });

    return formIsValid;
  }

  handleCancel = () => {
    this.setState({mnombre: '', visible: false});
  };

  handleOk = async () => {
    debugger;
    let data = { 
      nombre: this.state.nombre
    }

    if (this.handleValidation()) {
      this.setState({
        disabledSave: true
      });
      this.save(data, insertRoles);
      
    } else {
      console.log(data);
    }
  };

  handleResetForm = () => {
    this.setState({
      nombre: '',
      disabledSave: false,
    });
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    //this.update();
  }

  handleChange = (event) => {
    let fields_ = this.state.fields;

    let name = event.target.name;
    fields_[name] = event.target.value;

    this.setState({ [name]: event.target.value, fields: fields_ })
  }

  async componentDidMount() {
    //debugger;
    this.setState({loading:true});//mascara para mostrar cargando
    
    await this.getRoles();
  }
  
  async save(data, url) {
    
    
    try {
      let token = this.props.session.auth_token;
      this.setState({ loading: true });
      let resp = await post(data, url, token);
      
      this.setState({loading: false, visible: false});
      await this.getRoles();
      this.handleResetForm();
    } catch (Error) {
      console.error(Error);
    }
  }

  agregarRol = async () => {
    this.setState({
      visible: true
    });
  }

  getRoles = async () => {
    const {auth_token} = this.props.session;
    this.setState({ loading: true });
    let resp = await get(getRoles, auth_token)
    let response = [];

    resp.data.forEach(element => {
      let dataI = {
        id: element.id,
        nombre: element.nombre,
        isActive: (element.isActive == 1)? 'Activo' : 'Inactivo'
      }

      response.push(dataI);
    });

    if(response.length > 0){
      this.setState({
        dataSource : response
      });
      this.setState({ loading: false });
    }
    
    console.log(this.state.dataSource)
    
  }
  
  render() { 
    const dashColumns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre"
      },
      {
        title: "Estado",
        dataIndex: "isActive",
        key: "isActive"
      }
    ];

    const {loading} = this.state;
    return (
      <div>
        <Spin spinning={loading}>
        <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
              <Card title="Roles" className="gx-card" extra={
            <span>
              <Button block type="primary" onClick={this.agregarRol} style={{marginBottom: "0px", marginRight: "0px"}}>
                  <Icon type="plus" />
                  Agregar Rol
                </Button>       
              </span>}> {/**/}
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={this.state.dataSource}
                  columns={dashColumns}
                  pagination={false} 
                />
              </Card>
            </Col>
            </Row>
        </Spin>
        <Modal
          title="Agregar Nuevo Rol"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Confirmar" type="primary" disabled = {this.state.disabledSave} onClick={this.handleOk}>
              Guardar
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>
          ]}
        >
          <Form layout="vertical" onSubmit={this.handleSubmit}>
              <FormItem {...formItemLayout} label="Nombre">
                  <Input name="nombre" placeholder="" required={true} type="text" className="center-block" onChange={this.handleChange} />
                  <span style={{ color: "red" }}>{this.state.errors["nombre"]}</span>
              </FormItem>	
          </Form>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(ListRoles);