// import { listar, modificar, crear, eliminar, getUser } from "routes/api/Llamadas"
//import axios from 'axios';
export const pdf = async (data, url, token) => {
    try {
        //console.log(data)
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
                "Cache-Control": 'no-store, must-revalidate, no-store, must-revalidate'
            },
        })
        //let json = await response.json()
        return response.blob()
    } catch (err) {
        console.log("Error ==> ", err)
        return err
    }
}

export const getUser = async (url, thisToken) => {
    try {
        let response =  await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization':'Bearer ' + thisToken,
            },
            "Cache-Control": 'no-store, must-revalidate'
             })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const listarReservasCoche = async (url) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With' :  'XMLHttpRequest',
            //'Authorization':'Bearer '+ token,
            "Cache-Control": 'no-store, must-revalidate'
            },
             })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}
export const listar = async (url, token) => {
    try {
        console.log('url');
        console.log(url);
        console.log('url');
        console.log('token');
        console.log(token);
        console.log('token');
        let response =  await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With' :  'XMLHttpRequest',
            'Authorization':'Bearer '+ token,
            "Cache-Control": 'no-store, must-revalidate'
            },
             })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}
export const listarPrivado = async (url, token) => {
    try {
        let response =  await fetch(url, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With' :  'XMLHttpRequest',
            'Authorization':'Bearer '+ token,
            "Cache-Control": 'no-store, must-revalidate'
            },
             })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const listarDocumentos = async (data, url, token) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
                "Cache-Control": 'no-store, must-revalidate'
            },
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}
export const descargarDocumento = async (data, url, token) => {
    //console.log("token: ",  token)
    try {
        let response =  await fetch(url +"/"+data.uuid+'/'+data.user_id, {
            method: 'GET',
            //body: JSON.stringify(data),
            redirect: 'follow',
            responseType: 'blob',
            headers: {
                'Accept' :'application/json',
                'cache-control' :'no-cache',
                //'Content-Type': 'application/json',
                //'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response //.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const guardarDocumento = async (formdata, url, token) => {
    //console.log("token : ", token )
    //console.log("dentro de llamada guardarDocumento. Data: ", formdata)
    //console.log( "FILE:", formdata.get('name') );
    //console.log( formdata.get('description') );
    //console.log( formdata.get('type') );
    //console.log( formdata.get('extension') );
    //console.log( formdata.get('empleado_id') );

    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                //'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const guardarFoto = async (formdata, url, token) => {
    //console.log("dentro de llamada guardfarFoto. Data: ", formdata)
    //console.log( "empleado_id", formdata.get('id') );
    //console.log( formdata.get('image') );

    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                //'Content-Type': 'multipart/form-data',
                //'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        console.log(json)
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}
export const eliminar = async (data, url) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                //'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const crear = async (data, url) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                //'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const modificar = async (data, url) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                //'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate, no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const post = async (data, url, token) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
                "Cache-Control": 'no-store, must-revalidate, no-store, must-revalidate'
            },
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
        return err
    }
}

export const put = async (data, url, token) => {
    try {

        let response =  await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
                "Cache-Control": 'no-store, must-revalidate, no-store, must-revalidate'
            },
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
        return err
    }
}

export const get = async (url, token) => {
    try {
        let response =  await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                'Authorization':'Bearer '+ token,
                "Cache-Control": 'no-store, must-revalidate, no-store, must-revalidate'
            },
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
        return err
    }
}

export const restaurar = async (data, url) => {
    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With' :  'XMLHttpRequest',
                //'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}

export const postWithDocument = async (formdataF, url, token) => {

    var formdata = new FormData();
    formdata.append("file", formdataF);

    try {
        let response =  await fetch(url, {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                'Authorization':'Bearer '+ token,
            },
            "Cache-Control": 'no-store, must-revalidate'
        })
        let json = await response.json()
        return json
    } catch (err) {
        console.log("Error ==> ", err)
    }
}