import React from "react";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, Legend} from "recharts";
//import {fichajes} from "../data";
import moment from "moment";

function CustomTooltip({ payload, label, active }) {
  //debugger;
  if (active) {
    if(payload)  {
    let h_or = payload[0].value===0 ? 0 : Math.floor(payload[0].value / 60)
    let m_or = payload[0].value===0 ? 0 :Math.floor(payload[0].value % 60)
  
    let h_ex = payload[1].value===0 ? 0 : -Math.floor((payload[1].value) / 60)
    let m_ex = payload[1].value===0 ? 0 : -Math.floor((payload[1].value) % 60)
      return (
        <div className="custom-tooltip">
          <p className="recharts-tooltip-label">{`${moment(payload[0].payload.fecha).format("DD/MM/YYYY")}`}</p>

          <ul className="recharts-tooltip-item-list">
              <li className="recharts-tooltip-item">{`${payload[0].dataKey}: ${h_or}h ${m_or}m`}</li>
              <li className="recharts-tooltip-item">{`${payload[1].dataKey}: ${h_ex}h ${m_ex}m`}</li>
          </ul>
        </div>
      );
    }
    }
  
    return null;
  }
  const mes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  
  class FichajeMensual extends React.Component{
    constructor(props) {
      super(props)
      this.state={}
    }
    render(){
      //console.log("recuento : ", this.props.recuento)
      return(
  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Fichaje de {mes[moment().format("MM")-1]}</h6>
    <ResponsiveContainer width="100%" height={140}>
      <AreaChart data={this.props.recuento}
                 margin={{top: 10, right: 0, left: -20, bottom: 0}}>
        <Legend/>
        <Tooltip wrapperStyle={{ margin: 0, padding:"10px", border: "1px solid rgb(204, 204, 204)", fontSize: "14px", color:"#666" , 
        backgroundColor: "#e0e0e0"
       // 'rgb(255, 255, 255)' 
        }}  content={<CustomTooltip />}/>
        <CartesianGrid horizontal={true} strokeDasharray="3 3"/>
        <Area type='monotone' dataKey='ordinarias' fillOpacity={1} stroke='#038FDE' fill='#038FDE'/>
        <Area type='monotone' dataKey='otras' fillOpacity={1} stroke='#FE9E15' fill='#FE9E15'/>
      </AreaChart>
    </ResponsiveContainer>
  </div>
);
    }
  
  }
export default FichajeMensual;