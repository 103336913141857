export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_SESSION = "ADD_SESSION";
export const ADD_EMPLEADOS = "ADD_EMPLEADOS";

export const EMPLEADOS_DATA_LOADED = "EMPLEADOS_DATA_LOADED";
export const EMPLEADOS_DATA_REQUESTED = "EMPLEADOS_DATA_REQUESTED";

export const USER_DATA_REQUESTED = "USER_DATA_REQUESTED";
export const USER_DATA_LOADED = "USER_DATA_LOADED";

export const LOGIN_LOADED = "LOGIN_LOADED";
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";

export const ADD_FOTO = "ADD_FOTO";

export const VACACIONES_DATA_LOADED = "VACACIONES_DATA_LOADED";
export const VACACIONES_DATA_REQUESTED = "VACACIONES_DATA_REQUESTED";

export const BOLSA_COMPENSACIONES_DATA_LOADED = "BOLSA_COMPENSACIONES_DATA_LOADED";
export const BOLSA_COMPENSACIONES_DATA_REQUESTED = "BOLSA_COMPENSACIONES_DATA_REQUESTED";

export const INFORME_BAJA_DATA_LOADED = "INFORME_BAJA_DATA_LOADED";
export const INFORME_BAJA_DATA_REQUESTED = "INFORME_BAJA_DATA_REQUESTED";

export const INFORME_AUSENCIA_DATA_LOADED = "INFORME_AUSENCIA_DATA_LOADED";
export const INFORME_AUSENCIA_DATA_REQUESTED = "INFORME_AUSENCIA_DATA_REQUESTED";

export const DOCUMENTOS_DATA_LOADED = "DOCUMENTOS_DATA_LOADED";
export const DOCUMENTOS_DATA_REQUESTED = "DOCUMENTOS_DATA_REQUESTED";

export const RECUENTO_HORA_DATA_LOADED = "RECUENTO_HORA_DATA_LOADED";
export const RECUENTO_HORA_DATA_REQUESTED = "RECUENTO_HORA_DATA_REQUESTED";
