import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';

// Add this import:
import {AppContainer} from 'react-hot-loader';


//import registerServiceWorker from './registerServiceWorker'; //se saca para no tener problemas con la cache
//import { unregister as unregisterServiceWorker } from './registerServiceWorker'

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
//registerServiceWorker(); //se saca para no tener problemas con la cache
//unregisterServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
