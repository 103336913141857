import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import {
  Col, Row,Spin,
//   Alert, Button,
//   message, 
//   Select, Input,
//   Form, Card,
//   Modal, Table
} from "antd";
import IconWithTextCard from "./componentes/IconWithTextCard";
import { getFichajeWithData } from "../api/urls";

import FichajeLog from "routes/RRHH/Fichaje/FichajeLog";

import {
  selectInfoDash,
  insertFichaje,
  getLastFichaje,
  saveDraft
} from "routes/api/urls";
import { post, get } from "routes/api/Llamadas";
import moment from "moment";
const mapStateToProps = state => ({
    session: state.settings.session
});
class Daily extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            usuario: {},
            empleado: null,
            empleado_id: this.props.session.empleado_id
                ? this.props.session.empleado_id : "",
            loading: false,
            token : this.props.session.auth_token
        };
        //this.iniciarJornada.bind(this);
    }
    componentDidMount(){

    }
    async tick(){
        let ultimosact = [];
        let { token, empleado_id } = this.state;
        ultimosact = await post({ empleado_id }, getFichajeWithData, token);debugger;
        this.setState({fichajes: ultimosact.data})
    }
    render() { // donde se construye todo el layout
        return(
            <div>
                <Spin spinning={this.state.loading}>
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1" >
                    <FichajeLog
                        empleado_id={this.props.session.empleado_id}
                        contexto="empleado"
                        dataFichaje={this.state.fichajes}/> 
                    </Col>
                </Row>
                    {/* <Row>
                        <Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:8, offset:8}}   className="gx-text-left">
                        <IconWithTextCard
                            cardColor="teal" //{`${this.state.cardColor}`}
                            title="Edición Fichaje" //{this.state.titlebtn}
                            //actions={this.state.actions}
                            icon="fa fas-play" //{this.state.iconb}
                        />
                        </Col>
                    </Row> */}
                </Spin>
            </div>
        );
    }
}

export default connect(mapStateToProps, {})(Daily);